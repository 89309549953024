import React, { FC } from "react";
import {
  Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button,
} from "@mui/material";
import { HHDatePicker } from "../../parts/HHDatePicker";

interface ConfirmDeleteModalProps {
  open: boolean;
  onClose: () => void;
  onConfirmDelete: () => void;
  effectiveUntilDate: Date | null;
  setEffectiveUntilDate: (date: Date | null) => void;
}

const ConfirmDeleteModal: FC<ConfirmDeleteModalProps> = (
  { open, onClose, onConfirmDelete, effectiveUntilDate, setEffectiveUntilDate },
) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Confirm Delete</DialogTitle>
    <DialogContent>
      <Typography>Are you sure you want to delete this tax rate?</Typography>
      <HHDatePicker
        label="Effective Until"
        value={effectiveUntilDate}
        onChange={(newValue) => setEffectiveUntilDate(newValue)}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button color="error" onClick={onConfirmDelete} disabled={!effectiveUntilDate}>Delete</Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmDeleteModal;
