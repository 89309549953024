import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Modal, Container, Button,
} from 'react-bootstrap';
import { first, HashOf, hasKey, strVal, values } from '@jamesgmarks/utilities';
import { fetchEmailBodies } from '../../../redux/features/documents/actions';

import { getContactGroups } from './getContactGroups';
import { getDefaultContactsForGroup, DocumentGroupSection } from './DocumentGroupSection';
import { camelCaseToCapitalized } from '../../../app-utils/helpers';
import { IBillingAccount } from '../../../entity-interfaces/IBillingAccount';
import { IOwnershipGroups } from '@llws/lift-entity-interfaces';
import { sendMultipleDocumentNotifications } from 'src/redux/features/documents/actions';
import { useAppSelector } from 'src/redux/hooks';
import { EDocumentType } from '@hydra/internal';

export type BaseDocument = {
  id?:number,
  billingAccountId?: string,
  accountId?:string,
  freshbooksClientId?:number,
  billingAccount?: IBillingAccount,
  ownershipGroupId?: number | null,
  ownershipGroup?: IOwnershipGroups | null
  documentType?: EDocumentType
  addCsv?: boolean
};

interface ISendDocumentsModalProps<T extends BaseDocument> {
  documents: T[];
  show: boolean;
  onHide: () => void;
  onSend: () => void;
}

export const SendDocumentsModal = <T extends BaseDocument>({
  documents,
  show,
  onHide: parentOnHide,
  onSend,
}: ISendDocumentsModalProps<T>) => {

  const documentTypeMapping = {
    [EDocumentType.invoice]: 'Invoice',
    [EDocumentType['credit-note']]: 'Credit Note',
    [EDocumentType.statement]: 'Statement',
  };

  const documentSubjectMapping = {
    [EDocumentType.invoice]: null,
    [EDocumentType['credit-note']]: 'New Credit Notes From Rentsync (Landlord Web Solutions)',
    [EDocumentType.statement]: 'Your Statement of Account from Rentsync (Landlord Web Solutions Inc.)',
    'mixed': 'New Documents From Rentsync (Landlord Web Solutions)',
  };

  const firstDocumentType = documents[0]?.documentType;
  if(!firstDocumentType) {
    throw new Error('Document type not found for document' + documents[0].id);
  }
  const documentName = documentTypeMapping[firstDocumentType] || 'Document';

  const [ groupContacts, setGroupContacts ] = useState<HashOf<number[]>>({});

  const { unsortedGroups, sortedGroupKeys } = getContactGroups(documents);

  const setSelectedContactsForGroup = (groupKey: string, contacts: number[]) => {
    setGroupContacts({
      ...groupContacts,
      [ groupKey ]: contacts,
    });
  };

  const [ removedSendCards, setRemovedSendCards ] = useState<string[]>([]);

  const onHide = (
    useCallback(() => {
      setRemovedSendCards([]);
      parentOnHide();
    },
    [ parentOnHide ])
  );

  const filteredSortedGroupKeys = (
    useMemo(() => sortedGroupKeys.filter((key) => !removedSendCards.includes(strVal(key))),
      [ removedSendCards, sortedGroupKeys ])
  );

  const emailBodies = useAppSelector((state) => state.documents.emailBodies);

  const [ groupEmailContents, setGroupEmailContents ] = useState<HashOf<{ subject: string, body: string }>>(
    filteredSortedGroupKeys.reduce(
      (acc, key) => {

        return {
          ...acc,
          [key]: {
            subject: documentSubjectMapping[firstDocumentType],
            body: emailBodies[firstDocumentType],
          },
        };
      },
      {},
    ),
  );

  const setSelectedEmailContentsForGroup = (groupKey: string, subject: string, body: string) => {
    setGroupEmailContents({
      ...groupEmailContents,
      [ groupKey ]: { subject, body },
    });
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBodies = async () => {
      if (Object.keys(emailBodies).length === 0) { // Check if email bodies are already present
        await fetchEmailBodies();
      }
      setLoading(false); // Set loading to false after checking/fetching email bodies
    };
    fetchBodies();
  }, [emailBodies]);

  useEffect(() => {
    if (filteredSortedGroupKeys.length === 0) {
      onHide();
    }
  },
  [ filteredSortedGroupKeys.length, onHide ]);

  return (
    <Modal
      centered
      dialogClassName="modal-90w"
      backdrop="static"
      animation={false}
      show={show}
    >
      <Modal.Header closeButton onHide={onHide}>
        <Modal.Title id="contained-modal-title-vcenter">
          {`Send ${camelCaseToCapitalized(documentName)}s by Email`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          {loading ? ( // Show loading indicator while fetching email bodies
            <div>Loading...</div>
          ) : (
            filteredSortedGroupKeys.map(
              (key) => {
                const documents = unsortedGroups[key];
                const ownershipGroup = first(documents)!.ownershipGroup;

                return (
                  <DocumentGroupSection
                    key={strVal(key)}
                    documents={documents}
                    ownershipGroup={ownershipGroup ?? undefined}
                    groupKey={`${key}`}
                    setSelectedContactsForGroup={setSelectedContactsForGroup}
                    setRemovedSendCards={setRemovedSendCards}
                    emailBody={groupEmailContents[key]?.body || emailBodies[firstDocumentType]}
                    emailSubject={groupEmailContents[key]?.subject}
                    setSelectedEmailContentsForGroup={setSelectedEmailContentsForGroup}
                    setGroupEmailContents={setGroupEmailContents}
                    groupEmailContents={groupEmailContents}
                  />
                );
              },
            )
          )}
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={values(groupContacts).some((arr) => arr.length === 0)}
          onClick={() => {
            const notifications = (
              filteredSortedGroupKeys.map(
                (key) => {
                  const documents = unsortedGroups[key];
                  const ownershipGroup = first(documents)!.ownershipGroup!;
                  const clients = first(documents)!.billingAccount?.clients;
                  const client = first(clients!)!;
                  const billingContacts = (
                    hasKey(groupContacts, `${key}`)
                      ? groupContacts[key]
                      : (
                        getDefaultContactsForGroup(client, ownershipGroup)
                          .filter((contact) => contact.receivesInvoices)
                          .map((contact) => contact!.billingContact!.id!)
                      )
                  );

                  return {
                    toBillingContactIds: billingContacts,
                    subject: groupEmailContents[key]?.subject,
                    body: groupEmailContents[key]?.body,
                    documents: documents.map((document) => ({
                      id: document.id,
                      documentType: document.documentType,
                      addCsv: document.addCsv,
                    })),
                  };
                },
              )
            );

            console.info(`${notifications.length} notification${notifications.length - 1 ? 's' : ''} sent.`);

            sendMultipleDocumentNotifications(notifications);
            onSend();
            onHide();
          }}
        >
          Send All
        </Button>
        <Button onClick={onHide} variant="secondary">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};
