import { Button } from "@mui/material";
import { useState } from "react";

interface AutoDisableButtonProps extends Omit<React.ComponentProps<typeof Button>, 'onClick'> {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void> | void;
}

export const AutoDisableButton: React.FC<AutoDisableButtonProps> = ({ onClick, children, ...props }) => {
  const [disabled, setDisabled] = useState(false);

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    if (disabled) return;
    setDisabled(true);
    try {
      await Promise.all([
        onClick(event),
        new Promise<void>((resolve) => setTimeout(resolve, 5000)),
      ]);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <Button {...props} onClick={handleClick} disabled={disabled}>
      {disabled ? "Processing..." : children}
    </Button>
  );
};
