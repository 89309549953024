import { useState } from "react";
import {
  Box,
  Button,
  Checkbox, Table, TableBody, TableContainer, TableHead,
} from "@mui/material";

import { DocumentListTableRow } from "./DocumentListTableRow";
import { StyledTableCell, StyledTableRow } from "../../parts/mui/StyledTables";
import { TTableData } from "./table-cell-getters";
import { SendDocumentsModal } from "../Invoices/SendDocumentsModal";
import { IBillingAccount } from "../../../entity-interfaces/IBillingAccount";
import { DocumentTypes } from "../../../../../hydra-api/pdfGeneration";
import { CreditNote, Invoice, Statement } from "../../../../../entities/hydra";
import { keys } from "@jamesgmarks/utilities";
import { EDocumentType } from "@hydra/internal";
interface IDocumentListTableProps<T extends { id: number, billingAccount: IBillingAccount }> {
  documents: T[];
  fields: TTableData<T>[];
}
export const isInvoiceDocument = (
  doc: DocumentTypes & { documentType: EDocumentType},
): doc is Invoice & { documentType: EDocumentType } => doc.documentType === 'invoice' || !!doc.invoiceDate;

export const isCreditNoteDocument = (
  doc: DocumentTypes & { documentType: EDocumentType },
): doc is CreditNote & { documentType: EDocumentType } => doc.documentType === 'credit-note' || !!doc.noteDate;

export const isStatementDocument = (
  doc: DocumentTypes & { documentType: EDocumentType },
): doc is Statement & { documentType: EDocumentType } => doc.documentType === 'statement' || !!doc.statementDate;

export const DocumentListTable = <T extends { id: number, billingAccount: IBillingAccount }>({
  documents,
  fields,
}: IDocumentListTableProps<T>) => {
  const [ selectedDocumentIds, setSelectedDocumentIds ] = useState<number[]>([]);

  const getSelectedDocuments = () => documents.filter((d) => selectedDocumentIds.includes(d.id)).map((d) => {

    console.log({document: d});

    const documentTypes = {
      'invoice': isInvoiceDocument(d),
      'credit-note': isCreditNoteDocument(d),
      'statement': isStatementDocument(d),
    };

    const documentType = keys(documentTypes).find((key) => documentTypes[key] === true);

    if (!documentType) {
      throw new Error('Document type not found');
    }
    return {
      ...d,
      documentType: EDocumentType[documentType],
    };

  });

  const [showSendModal, setShowSendModal] = useState(false);

  const setAllSelectedStates = () => {
    setSelectedDocumentIds(documents.length === selectedDocumentIds.length ? [] : documents.map((d) => d.id));
  };

  return (
    <>
      {
        showSendModal
        && (
          <SendDocumentsModal
            show={showSendModal}
            onHide={() => setShowSendModal(false)}
            onSend={() => setSelectedDocumentIds([])}
            documents={getSelectedDocuments()}
          />
        )
      }

      <Box textAlign='right' mb={2}>
        <Button
          disabled={selectedDocumentIds.length === 0}
          variant='contained'
          onClick={() => setShowSendModal(true)}
        >
          Send Documents
        </Button>
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell style={{ backgroundColor: 'lightgray' }}>
                <Checkbox
                  checked={selectedDocumentIds.length === documents.length}
                  color='secondary'
                  onChange={() => setAllSelectedStates()}
                />
              </StyledTableCell>
              {
                (fields ?? []).map((field) => (
                  <StyledTableCell key={field.name}>{field.name}</StyledTableCell>
                ))
              }
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {
              documents.map((document) => (
                <DocumentListTableRow
                  key={document.id}
                  document={document}
                  fields={fields}
                  selected={selectedDocumentIds.includes(document.id)}
                  setSelected={setSelectedDocumentIds}
                />
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
