import { createSlice } from '@reduxjs/toolkit';
import { IActionType } from '../../utils';
import { IDocumentMap } from './interfaces';
import { DownloadQueues } from '../../../../../rentsync-api/instances/downloadQueueProcessor';

export enum EDocumentsLoadedState {
  idle = 'idle',
  loading = 'loading',
  loaded = 'loaded',
  sent = 'sent',
}

interface IDocumentsState {
  documents: IDocumentMap;
  loadedState: EDocumentsLoadedState;
  downloadQueues: DownloadQueues;
  emailBodies: Record<string, string>;
}

const documentsSlice = createSlice({
  name: 'documents',
  initialState: {
    documents: {},
    loadedState: EDocumentsLoadedState.idle,
    emailBodies: {},
  } as IDocumentsState,
  reducers: {
    loadReceivedDocuments: (state, { payload }: IActionType<IDocumentMap>) => {
      state.documents = payload;
    },
    setLoadedState: (state, { payload }: IActionType<EDocumentsLoadedState>) => {
      state.loadedState = payload;
    },
    downloadQueuesReceived: (state, action: IActionType<DownloadQueues>) => {
      state.downloadQueues = action.payload;
    },
    loadEmailBodies: (state, { payload }: IActionType<Record<string, string>>) => {
      state.emailBodies = payload;
    },
  },
});

export const {
  loadReceivedDocuments,
  setLoadedState,
  downloadQueuesReceived,
  loadEmailBodies,
} = documentsSlice.actions;

export default documentsSlice.reducer;
