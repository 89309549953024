import { Box, CircularProgress, Grid, Typography } from "@mui/material";

import { DocumentListTabs } from "./DocumentListTabs";
import { DocumentRetrievalForm } from "./DocumentRetrievalForm";
import { EDocumentsLoadedState } from "../../../redux/features/documents/documentsSlice";
import { useAppSelector } from "../../../redux/hooks";
import { keys } from "@jamesgmarks/utilities";
import { useEffect } from "react";

export const SendDocuments = () => {
  const loadedState = useAppSelector((state) => state.documents.loadedState);
  const documents = useAppSelector((state) => state.documents.documents);

  const numberOfFoundDocuments = (
    keys(documents)
      .reduce((acc, key) => (
        acc + documents[key].length
      ), 0)
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} mt={2.5} textAlign='center'>
        <Typography variant='h4'>Send Documents</Typography>
      </Grid>

      <Grid item container xs={12} lg={9} mx='auto'>
        <Grid item xs={12}>
          <DocumentRetrievalForm />
          {
            loadedState === EDocumentsLoadedState.loading
              ? (
                <Box width='100%' mt={12} textAlign='center'>
                  <CircularProgress color="secondary" />
                </Box>
              )
              : (
                (numberOfFoundDocuments === 0 && loadedState === EDocumentsLoadedState.loaded)
                  ? (
                    <Grid item my={6} textAlign='center' xs={12}>
                      <Typography variant='h5'>No documents found...</Typography>
                    </Grid>
                  )
                  : <DocumentListTabs documents={documents} />
              )
          }
        </Grid>
      </Grid>
    </Grid>
  );
};
