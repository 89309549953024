import { useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import {
  Box,
  Button,
  Card,
  Grid,
  Typography,
} from '@mui/material';

import { intVal, Nullable, strVal } from '@jamesgmarks/utilities';

import { BillingProfileCard } from './BillingProfileCard';
import { ClientDropdown } from '../../parts/ClientDropdown';
import {
  getIsLastMonthLocked,
  loadBillingAccountRecentInvoices,
  loadBillingAccountWithClients,
  loadCurrentHydraClient,
} from '../../../redux/features/clients/actions';
import { useAppSelector } from '../../../redux/hooks';
import { RecentInvoice } from './RecentInvoice';
import { TabMenu } from './Tabs';
import { getLastMonth } from './ClientsListView';
import { updateBillingAccountRuntimeGroup } from '../../../redux/features/accounts/actions';
import { RuntimeGroupDropdown } from '../../parts/RuntimeGroupDropdown';
import { showMessage } from '../../../redux/features/messaging/actions';
import { RerunModal } from './RerunModal';
import { IClientMatch } from '../../../../../rentsync-api/IClientMatch';
import { useAuth } from '../../../customHooks/useAuth';

export const ClientSingleView = () => {
  const client = useAppSelector((state) => state.clients.currentHydraClient);
  const clientMatchList = useAppSelector<IClientMatch[]>((state) => state.clients.clientsList);
  const billingAccount = useAppSelector((state) => state.clients.currentClientBillingAccount);
  const recentInvoices = useAppSelector((state) => state.clients.currentClientBillingAccountRecentInvoices);
  const isLastMonthLocked = useAppSelector((state) => state.clients.isLastMonthLocked);

  const billingAccountId = intVal(useParams<{ billingAccountId?: string }>().billingAccountId);

  const [ clientId, setClientId ] = useState<Nullable<number>>(billingAccount?.primaryClientId ?? null);
  const [ runtimeGroupId, setRuntimeGroupId] = useState<Nullable<number>>(billingAccount?.runtimeGroupId ?? null);
  const [ showModal, setShowModal ] = useState(false);

  const { hasPermission } = useAuth();

  useEffect(() => {
    if (billingAccountId) {
      loadBillingAccountWithClients(billingAccountId);
    }
  }, [ billingAccountId ]);

  useEffect(() => {
    if (billingAccountId && billingAccount) {
      loadBillingAccountRecentInvoices(billingAccount.accountId, 20);
    }
  }, [ billingAccountId, billingAccount ]);

  useEffect(() => {
    if (clientId && clientId !== client?.clientId) {
      loadCurrentHydraClient(strVal(clientId));
    }
  }, [ clientId, client?.clientId ]);

  useEffect(() => {
    if (billingAccount) {
      setClientId(billingAccount.primaryClientId);
      setRuntimeGroupId(billingAccount.runtimeGroupId);
    }
  }, [ billingAccount ]);

  useEffect(() => {
    if (billingAccount && clientId) {
      getIsLastMonthLocked();
    }
  }, [ billingAccount, clientId, isLastMonthLocked ]);

  const clientRuntimeGroup = useMemo(() => {
    return (
      (billingAccount && runtimeGroupId)
        ? billingAccount.clientRuntimeGroup
        : null
    );
  }, [billingAccount, runtimeGroupId]);

  const lastMonthData = useMemo(() => {
    const lastMonth = getLastMonth();
    return {
      month: lastMonth.getMonth() + 1,
      year: lastMonth.getFullYear(),
    };
  }, []);

  const currentClient = useMemo(() => {
    const selectedClient = (
      clientMatchList.length > 0 && client && clientId === client.clientId
        ? clientMatchList.find((client) => client.id === clientId && client.accountId === billingAccount?.accountId)
        : null
    );
    return selectedClient ?? null;

  }, [clientMatchList, client, clientId, billingAccount]);

  const disableRerun = useMemo(() => (
    isLastMonthLocked
      || clientId !== client?.clientId
      || (!hasPermission('RERUN_ACCESS') && !hasPermission('FULL_ACCESS'))
  ), [clientId, client, currentClient, isLastMonthLocked, hasPermission]);

  const handleRuntimeGroupSave = async () => {
    if (runtimeGroupId === null) {
      showMessage({
        message: `Run time group id is needed. Please make sure run time group is selected`,
        severity: 'error',
        asAlert: true,
      });
      return;
    }
    await updateBillingAccountRuntimeGroup(
      {
        accountId: billingAccount?.accountId ?? billingAccountId,
        clientRuntimeGroupId: runtimeGroupId,
      },
    );
  };

  return (
    <>
      <Grid container px={2}>
        <Grid
          item
          container
          direction='column'
          lg={8}
          mb={'auto'}
          sx={{
            backgroundImage: 'linear-gradient(to top, #fff1eb 0%, #ace0f9 100%)',
            borderRadius: '10px',
          }}
          xs={12}
        >
          <Grid item container pt={3} pb={2}>

            <Grid item container justifyContent='center' mb={2.5} px={1} md={7}>
              <Grid item xs={10}>

                <Card sx={{ borderRadius: '20px', px: 2, background: '#f5f5f5' }}>
                  <Grid item container textAlign='center'>
                    <Grid item xs={6}>
                      <Box mt={1}>
                        <Typography sx={{ fontSize: '0.8rem', color: 'gray' }}><u>Client Name</u></Typography>
                        <Typography variant='h6'>{client?.clientName}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box my={1}>
                        <Typography sx={{ fontSize: '0.8rem', color: 'gray' }}><u>Account ID</u></Typography>
                        <Typography variant='h6'>{billingAccountId}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item my={2}>
                    <Grid container spacing={2}>
                      {
                        clientId === billingAccount?.primaryClientId && (
                          <Grid item xl={3} lg={12} md={4} sm={3} xs={12}>
                            <Typography pt={0.5} variant='subtitle2'><b>Primary Client</b></Typography>
                          </Grid>
                        )
                      }
                      <Grid item style={{ flexGrow: 1 }}>
                        <ClientDropdown
                          allowedClientIds={(billingAccount?.clients ?? []).map((c) => c.clientId)}
                          clientId={clientId}
                          onClientChanged={
                            (client) => {
                              setClientId(client?.id ?? null);
                            }
                          }
                          showPartners={false}
                          starredClientId={billingAccount?.primaryClientId}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item my={2}>
                    <Grid container spacing={2}>
                      <Grid item xl={3} lg={12} md={4} sm={3} xs={12}>
                        <Typography  pt={0.5} variant='subtitle2'><b>Run Time Group</b></Typography>
                      </Grid>
                      <Grid item style={{ flexGrow: 1 }}>
                        <RuntimeGroupDropdown
                          id="runtime-group-selector"
                          onRuntimeGroupChange={
                            (runtimeGroupId) => {
                              setRuntimeGroupId(runtimeGroupId);
                            }
                          }
                          label='Run Time Group'
                          defaultValue={clientRuntimeGroup ?? null}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container mb={2} justifyContent='space-between'>
                    <Grid item>
                      <Button
                        variant='contained'
                        disabled={disableRerun}
                        color='primary'
                        onClick={() => setShowModal(true)}
                      >
                        Re-Run Client
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={handleRuntimeGroupSave}
                      >
                        Save Run Time Group
                      </Button>
                    </Grid>
                  </Grid>

                  {/* <Grid item container>
                  <Grid item mb={3} mt={2} pl={0.5} textAlign='center' xs={6}>
                    <Card>
                      <Typography variant='subtitle2'><u>Outstanding / Invoiced</u></Typography>
                      <Typography>$513.25 / $11,327.11</Typography>
                    </Card>
                  </Grid>
                </Grid> */}

                </Card>
              </Grid>

            </Grid>

            <Grid item container md={5} px={1}>
              <BillingProfileCard client={client} />
            </Grid>

          </Grid>

          <Grid item sx={{ overflow: 'hidden', overflowY: 'scroll' }}>
            <TabMenu />
          </Grid>

        </Grid>

        <Grid
          alignItems='flex-start'
          container
          item
          lg={4}
          p={4}
          spacing={2}
          textAlign='center'
        >
          <Grid
            item
            mb={2}
            xs={12}
          >
            <Typography sx={{ width: '100%' }} variant='h5'>
              <b>Recent Invoices</b>
            </Typography>
          </Grid>
          {
            recentInvoices
              .map(
                (invoice) => (
                  <Grid item key={invoice.id} xs={12} md={6}>
                    <RecentInvoice invoice={invoice} />
                  </Grid>
                ),
              )
          }
        </Grid>
      </Grid>
      <RerunModal
        client={currentClient}
        month={lastMonthData.month }
        setShowModal={setShowModal}
        showModal={showModal}
        year={lastMonthData.year}
      />
    </>
  );
};
