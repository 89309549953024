import { first, HashOf, keys } from "@jamesgmarks/utilities";
import { assertIInvoiceArray, IInvoice, isIInvoiceArray } from "../../../entity-interfaces/IInvoice";
import { assertIAugmentedCreditNoteArray, IAugmentedCreditNote } from "../../../interfaces/ICreditNote";
import { isAccountStatement } from "../../../../../hydra-api/statements";
import { IOwnershipGroups } from "@llws/lift-entity-interfaces";
import { IBillingAccount } from "src/entity-interfaces/IBillingAccount";
import { BaseDocument } from "./SendDocumentsModal";

export const getContactGroups = <T extends BaseDocument>(
  documents: T[],
) => {

  const unsortedGroups = documents.reduce<HashOf<T[]>>((grouped, document) => {
    const OGLabel = (
      ((document?.ownershipGroup?.clientHasBillingContacts ?? []).length === 0)
        ? null
        : `${document.ownershipGroupId ?? ''}`
    );
    const key = [ `${document.billingAccountId ?? document.accountId ?? document.freshbooksClientId}`, OGLabel ].filter(Boolean).join('-');
    return {
      ...grouped,
      [key]: [ ...(grouped[key] ?? []), document],
    };
  }, {});

  // A sorted list of the group keys based on what the values title will be.
  // Used for iterating over the groups in alphabetical order.
  const sortedGroupKeys = (
    keys(unsortedGroups)
      .sort((aKey, bKey) => {
        const getTitleKey = (key: string | number) => {
          // Information needed to construct group[key]'s title
          const invoicesKey = unsortedGroups[key];
          const ownershipGroupKey = first(invoicesKey)!.ownershipGroup;
          const clientsKey = first(invoicesKey)!.billingAccount?.clients;
          const clientKey = first(clientsKey!)!;

          return(
            ownershipGroupKey?.name
              ? `${clientKey?.clientName} Ownership Group: [${ownershipGroupKey.id}] ${ownershipGroupKey?.name}`
              : clientKey?.clientName ?? ' ISSUE'
          );
        };

        // Compare titles and return them in alphabetical order
        return (getTitleKey(aKey) < getTitleKey(bKey)) ? -1 : 1;
      })
  );

  console.log({unsortedGroups, sortedGroupKeys});

  return { unsortedGroups, sortedGroupKeys };
};
