import React from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TableSortLabel,
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { TOrderDirection } from '@llws/dynamic-router-utils';
import { TrackingCategory } from 'src/types/TrackingCategory';

interface TrackingCategoriesTableProps {
  categories: TrackingCategory[];
  order: TOrderDirection;
  orderBy: string;
  handleRequestSort: (property: string) => void;
  handleClickOpen: (id: number) => void;
  handleEditOpen: (category: TrackingCategory) => void;
}

const TrackingCategoriesTable: React.FC<TrackingCategoriesTableProps> = ({
  categories,
  order,
  orderBy,
  handleRequestSort,
  handleClickOpen,
  handleEditOpen,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sortDirection={orderBy === 'id' ? (order ===''? false:order) : false}>
              <TableSortLabel
                active={orderBy === 'id'}
                direction={orderBy === 'id' ? (order ===''? undefined:order) : 'asc'}
                onClick={() => handleRequestSort('id')}
              >
                ID
              </TableSortLabel>
            </TableCell>
            <TableCell sortDirection={orderBy === 'revenueTrackingCode' ? (order ===''? false:order) : false}>
              <TableSortLabel
                active={orderBy === 'revenueTrackingCode'}
                direction={orderBy === 'revenueTrackingCode' ? (order ===''? undefined:order) : 'asc'}
                onClick={() => handleRequestSort('revenueTrackingCode')}
              >
                Revenue Tracking Code
              </TableSortLabel>
            </TableCell>
            <TableCell sortDirection={orderBy === 'businessUnit' ? (order ===''? false:order) : false}>
              <TableSortLabel
                active={orderBy === 'businessUnit'}
                direction={orderBy === 'businessUnit' ? (order ===''? undefined:order) : 'asc'}
                onClick={() => handleRequestSort('businessUnit')}
              >
                Business Unit
              </TableSortLabel>
            </TableCell>
            <TableCell sortDirection={orderBy === 'businessName' ? (order ===''? false:order) : false}>
              <TableSortLabel
                active={orderBy === 'businessName'}
                direction={orderBy === 'businessName' ? (order ===''? undefined:order) : 'asc'}
                onClick={() => handleRequestSort('businessName')}
              >
                Business Name
              </TableSortLabel>
            </TableCell>
            <TableCell>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {categories.map(category => (
            <TableRow key={category.id}>
              <TableCell>{category.id}</TableCell>
              <TableCell>{category.revenueTrackingCode}</TableCell>
              <TableCell>{category.businessUnit}</TableCell>
              <TableCell>{category.businessName}</TableCell>
              <TableCell>
                <IconButton color="primary" onClick={() => handleEditOpen(category)}>
                  <Edit />
                </IconButton>
                <IconButton color="secondary" onClick={() => category.id !== null && handleClickOpen(category.id)}>
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TrackingCategoriesTable;
