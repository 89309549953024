import { useEffect, useState } from "react";
import { Button, Grid, TextField, TextFieldProps } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Nullable } from "@jamesgmarks/utilities";

import { getMonthStart, makeYmd } from "../../../app-utils";
import { getDocuments } from "../../../redux/features/documents/actions";
import { DocumentMultipleSelect } from "./DocumentMultipleSelect";
import { ClientMultiSelectDropdown } from "src/components/parts/ClientMultiSelectDropdown";
import { IClientDropdownItem } from "src/entity-interfaces/IClientDropdownItem";
import { EDocumentType } from "@hydra/internal";
import { TDocumentName } from "src/types/documents";

export const DocumentRetrievalForm = () => {
  const [ startDate, setStartDate ] = useState(
    () => { const d = new Date(); return getMonthStart(d.getFullYear(), d.getMonth() + 1); },
  );

  const [ endDate, setEndDate ] = useState(new Date());

  const [ documentNames, setDocumentNames ] = useState<TDocumentName[]>([ EDocumentType["credit-note"], EDocumentType.invoice, EDocumentType.statement ]);

  const [searchClients, setSearchClients] =useState<Nullable<IClientDropdownItem[]>>(null);
  const [ partnerSelected, setPartnerSelected ] = useState(false);

  const [ selectedClientId, setSelectedClientId ] = useState<Nullable<number>>(null);
  const [ billingAccountId, setBillingAccountId ] = useState<Nullable<number>>(null);

  const fetchDocuments = () => {
    documentNames.length > 0
    && (
      getDocuments(
        makeYmd(startDate, true),
        makeYmd(endDate, true),
        documentNames,
        searchClients?.map(c => c.freshbooksClientId) ?? [],
      )
    );
  };

  return (
    <Grid container justifyContent='center' textAlign='center'pb={1} pt={2}>
      <Grid
        item
        container
        xs={10}
        xl={8}
        sx={{ minWidth: 500 }}
      >
        <Grid item container xs={12} lg={9}>
          <Grid item container spacing={1} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid item xs={3.5}>
                <DatePicker
                  inputFormat="yyyy-MM-dd"
                  label="Start Date"
                  mask='____-__-__'
                  onChange={(d: Date | null) => d && setStartDate(d)}
                  renderInput={(props: TextFieldProps) => <TextField {...props} />}
                  showToolbar={false}
                  value={startDate}
                />
              </Grid>
              <Grid item xs={3.5}>
                <DatePicker
                  inputFormat="yyyy-MM-dd"
                  label="End Date"
                  mask='____-__-__'
                  onChange={(d: Date | null) => d && setEndDate(d)}
                  renderInput={(props: TextFieldProps) => <TextField {...props} />}
                  showToolbar={false}
                  value={endDate}
                />
              </Grid>
              <Grid item xs={5}>
                <DocumentMultipleSelect
                  documentNames={documentNames}
                  setDocumentNames={setDocumentNames}
                />
              </Grid>
            </LocalizationProvider>
          </Grid>
          <Grid item container xs={12}>
            <Grid item p={1} pl={0} xs={12}>
              <ClientMultiSelectDropdown
                onClientChanged={
                  (client) => {
                    const partnersInClientArray = (client ?? []).filter((c) => c.type === 'partner');
                    setSearchClients(client);
                    setPartnerSelected(partnersInClientArray.length > 0);
                  }
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container justifyContent='right' alignContent='flex-end' xs={12} lg={3}>
          <Grid item>
            <Button
              size='large'
              variant='contained'
              onClick={() => fetchDocuments()} sx={{ ml: 8 }}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
