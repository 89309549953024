import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'src/redux/hooks';
import { getMany, deleteOne, createOne, updateOne } from 'src/redux/features/dynamic-hydra/actions';
import {
  Typography, Button, TextField, TablePagination, InputAdornment, IconButton, Box, Container, Paper,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { IQueryCommandOptions, TOrderDirection } from '@llws/dynamic-router-utils';
import TrackingCategoriesTable from './TrackingCategoriesTable';
import TrackingCategoriesDialog from './TrackingCategoriesDialog';
import { TrackingCategory } from 'src/types/TrackingCategory';

export const TrackingCategories = () => {
  const categories = useAppSelector((store) => store.dynamicHydra.data.tracking_categories?.list || []);
  const metadata = useAppSelector((store) => store.dynamicHydra.metaData);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [newCategory, setNewCategory] = useState<TrackingCategory>({
    id: null,
    revenueTrackingCode: '',
    businessUnit: '',
    businessName: '',
  });
  const [order, setOrder] = useState<TOrderDirection>('asc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>('');

  const [editOpen, setEditOpen] = useState(false);
  const [editCategory, setEditCategory] = useState<TrackingCategory>({
    id: null,
    revenueTrackingCode: '',
    businessUnit: '',
    businessName: '',
  });

  const fetchCategories = () => {
    const encodedSearchTerm = encodeURIComponent(`%${debouncedSearchTerm}%`);
    const queryCommandOptions: IQueryCommandOptions = {
      orderBy: [{ field: orderBy, direction: order }],
      limit: rowsPerPage,
      page: page + 1,
      where: debouncedSearchTerm ? [
        { field: 'revenueTrackingCode', value: encodedSearchTerm, operator: 'like', condition: 'or' },
        { field: 'businessUnit', value: encodedSearchTerm, operator: 'like', condition: 'or' },
        { field: 'businessName', value: encodedSearchTerm, operator: 'like', condition: 'or' },
      ] : [],
    };
    getMany('tracking_categories', queryCommandOptions);
  };

  useEffect(() => {
    fetchCategories();
  }, [order, orderBy, page, rowsPerPage, debouncedSearchTerm]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    console.log({metadata});
  }, [metadata]);

  const handleDelete = async () => {
    if (selectedId !== null) {
      await deleteOne('tracking_categories', selectedId);
      fetchCategories();
      setDeleteOpen(false);
      setSelectedId(null);
    }
  };

  const handleCreate = async () => {
    await createOne('tracking_categories', JSON.stringify(newCategory));
    fetchCategories();
    setOpen(false);
    setNewCategory({
      id: null,
      revenueTrackingCode: '',
      businessUnit: '',
      businessName: '',
    });
  };

  const handleClickOpen = (id: number) => {
    setSelectedId(id);
    setDeleteOpen(true);
  };

  const handleClose = () => {
    setDeleteOpen(false);
    setSelectedId(null);
  };

  const handleNewCategoryOpen = () => {
    setOpen(true);
  };

  const handleNewCategoryClose = () => {
    setOpen(false);
    setNewCategory({
      id: null,
      revenueTrackingCode: '',
      businessUnit: '',
      businessName: '',
    });
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditOpen = (category: TrackingCategory) => {
    setEditCategory(category);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setEditCategory({
      id: null,
      revenueTrackingCode: '',
      businessUnit: '',
      businessName: '',
    });
  };

  const handleEdit = async () => {
    if (editCategory.id !== null) {
      await updateOne('tracking_categories', JSON.stringify(editCategory), editCategory.id);
      fetchCategories();
      handleEditClose();
    }
  };

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} style={{ padding: '24px', marginTop: '24px' }}>
        <Typography variant="h4" gutterBottom>
          Tracking Categories
        </Typography>
        <Box display="flex" alignItems="center" marginBottom="16px">
          <TextField
            variant="outlined"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            style={{ marginRight: '16px' }}
          />
          <Button variant="contained" color="primary" onClick={handleNewCategoryOpen}>
            Create New Category
          </Button>
        </Box>
        <TrackingCategoriesTable
          categories={categories}
          order={order}
          orderBy={orderBy}
          handleRequestSort={handleRequestSort}
          handleClickOpen={handleClickOpen}
          handleEditOpen={handleEditOpen}
        />
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={ metadata?.totalRecords || 0 }
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, page)=> setPage(page)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <TrackingCategoriesDialog
          open={deleteOpen}
          onClose={handleClose}
          onConfirm={handleDelete}
          title="Confirm Delete"
          content="Are you sure you want to delete this tracking category?"
          confirmText="Delete"
        />
        <TrackingCategoriesDialog
          open={open}
          onClose={handleNewCategoryClose}
          onConfirm={handleCreate}
          title="Create New Tracking Category"
          content={
            <>
              <TextField
                autoFocus
                margin="dense"
                label="Revenue Tracking Code"
                type="text"
                fullWidth
                value={newCategory.revenueTrackingCode}
                onChange={(e) => setNewCategory({ ...newCategory, revenueTrackingCode: e.target.value })}
              />
              <TextField
                margin="dense"
                label="Business Unit"
                type="text"
                fullWidth
                value={newCategory.businessUnit}
                onChange={(e) => setNewCategory({ ...newCategory, businessUnit: e.target.value })}
              />
              <TextField
                margin="dense"
                label="Business Name"
                type="text"
                fullWidth
                value={newCategory.businessName}
                onChange={(e) => setNewCategory({ ...newCategory, businessName: e.target.value })}
              />
            </>
          }
          confirmText="Create"
        />
        <TrackingCategoriesDialog
          open={editOpen}
          onClose={handleEditClose}
          onConfirm={handleEdit}
          title="Edit Tracking Category"
          content={
            <>
              <TextField
                autoFocus
                margin="dense"
                label="Revenue Tracking Code"
                type="text"
                fullWidth
                value={editCategory.revenueTrackingCode}
                onChange={(e) => setEditCategory({ ...editCategory, revenueTrackingCode: e.target.value })}
              />
              <TextField
                margin="dense"
                label="Business Unit"
                type="text"
                fullWidth
                value={editCategory.businessUnit}
                onChange={(e) => setEditCategory({ ...editCategory, businessUnit: e.target.value })}
              />
              <TextField
                margin="dense"
                label="Business Name"
                type="text"
                fullWidth
                value={editCategory.businessName}
                onChange={(e) => setEditCategory({ ...editCategory, businessName: e.target.value })}
              />
            </>
          }
          confirmText="Save"
        />
      </Paper>
    </Container>
  );
};