import { useEffect, useState } from "react";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, TablePagination,
} from "@mui/material";
import { getMany } from "src/redux/features/dynamic-hydra/actions";
import { useAppSelector } from "src/redux/hooks";

export const SendHistoryTable = () => {
  const documentSendHistory = useAppSelector(state => state.dynamicHydra.data.document_send_history?.list ?? []);
  const loadedState = useAppSelector(state => state.dynamicHydra.data.document_send_history?.loadedState);
  const totalRecords = useAppSelector(state => state.dynamicHydra.metaData?.totalRecords ?? 0);

  const [page, setPage] = useState(0);  // Current page (0-indexed)
  const [rowsPerPage, setRowsPerPage] = useState(10);  // Rows per page

  const fetchData = () => {
    getMany('document_send_history', {
      orderBy: [{ field: 'id', direction: 'desc' }],
      page: page + 1,
      limit: rowsPerPage,
    });
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);  // Reset to the first page when changing rows per page
  };

  return (
    <TableContainer component={Paper}>
      <Typography variant="h6" sx={{ p: 2 }}>
        Send History
      </Typography>
      {!loadedState || loadedState === 'loading' ? (
        <Typography sx={{ textAlign: 'center', p: 3 }}>Loading...</Typography>
      ) : (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date Created</TableCell>
                <TableCell>Document Type</TableCell>
                <TableCell>Document ID</TableCell>
                <TableCell>Recipient Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Queued Time</TableCell>
                <TableCell>Sent Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documentSendHistory.length > 0 ? (
                documentSendHistory.map((record) => (
                  <TableRow key={record.id}>
                    <TableCell>{new Date(record.created).toLocaleString()}</TableCell>
                    <TableCell>{record.documentType}</TableCell>
                    <TableCell>{record.documentId}</TableCell>
                    <TableCell>{record.name || 'N/A'}</TableCell>
                    <TableCell>{record.email}</TableCell>
                    <TableCell>{new Date(record.queuedTime).toLocaleString()}</TableCell>
                    <TableCell>{record.sentTime ? new Date(record.sentTime).toLocaleString() : 'Pending'}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No send history available.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          {/* Table Pagination */}
          <TablePagination
            component="div"
            count={totalRecords}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50]}
          />
        </>
      )}
    </TableContainer>
  );
};
