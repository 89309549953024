import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { HashOf, Nullable } from '@jamesgmarks/utilities';

import { IClientMatch } from '../../../../../rentsync-api/IClientMatch';
import { useAuth } from '../../../customHooks/useAuth';
import { useAppSelector } from '../../../redux/hooks';

interface IClientListItemSplitButtonProps {
  client: Nullable<IClientMatch>;

}

export const ClientListItemSplitButton = (
  {
    client,
  }: IClientListItemSplitButtonProps,
) => {
  const history = useHistory();
  const systemInfo = useAppSelector(state => state.systemInfo);
  const [open, setOpen] = useState(false);

  const anchorRef = useRef<HTMLDivElement>(null);

  const { hasPermission } = useAuth();

  const optionDetails: HashOf<{
    label: string,
    handler: () => void,
    visible: boolean,
  }> = {
    rerun: {
      label: 'Reruns Have Moved to Clients Page',
      handler: () => {
      },
      visible: hasPermission('RERUN_ACCESS') || hasPermission('FULL_ACCESS'),
    },
  };

  const handleMenuItemClick = (
    optionKey: string,
  ) => {
    optionDetails[optionKey]?.handler();
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const openClientSingleView = () => {
    const accountId = systemInfo.env.NODE_ENV === 'production' ? client?.accountId : client?.legacyAccountId;
    return history.push(`/clients/${accountId}`);
  };

  return (
    <>
      <Grid container direction="column" alignItems="center">
        <Grid
          item
          xs={12}
        >
          <ButtonGroup
            variant="contained"
            color='primary'
            ref={anchorRef}
            aria-label="split button"
          >
            <Button
              disabled={!client?.accountId}
              onClick={openClientSingleView}
            >
              View
            </Button>
            <Button
              color="primary"
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>

          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition
            nonce={undefined}
            onResize={() => {}}
            onResizeCapture={() => {}}>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={(e) => handleClose(e)}>
                    <MenuList id="split-button-menu">
                      {Object.keys(optionDetails).map((optionKey, index) => (
                        optionDetails[optionKey].visible && <MenuItem
                          key={optionKey}
                          disabled={index === 2}
                          onClick={(event) => handleMenuItemClick(optionKey)}
                        >
                          {optionDetails[optionKey].label}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
    </>
  );
};
