import { Dispatch, SetStateAction } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import {
  Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent,
} from '@mui/material';
import { keys } from '@jamesgmarks/utilities';

import { camelCaseToCapitalized, capitalizedToCamelCase } from '../../../app-utils/helpers';
import { assertValidDocumentNameArray } from '../../../redux/features/documents/interfaces';
import { EDocumentType } from '@hydra/internal';
import { TDocumentName } from 'src/types/documents';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, documentNames: readonly string[], theme: Theme) {
  return {
    fontWeight:
      documentNames.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

interface IDocumentMultipleSelectProps {
  documentNames: TDocumentName[];
  setDocumentNames: Dispatch<SetStateAction<TDocumentName[]>>;
}

export const DocumentMultipleSelect = ({
  documentNames,
  setDocumentNames,
}: IDocumentMultipleSelectProps) => {
  const theme = useTheme();

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const { target: { value } } = event;
    const newNames = typeof value === 'string' ? value.split(',') : value;
    setDocumentNames(
      assertValidDocumentNameArray(newNames.map((document) => capitalizedToCamelCase(document))),
    );

  };

  return (
    <Box>
      <FormControl sx={{ width: 300 }}>
        <InputLabel id="multiple-chip-label">Documents</InputLabel>
        <Select
          labelId="multiple-chip-label"
          multiple
          value={documentNames}
          onChange={handleChange}
          input={<OutlinedInput label="Documents" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => <Chip key={value} label={camelCaseToCapitalized(value)} />)}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {
            keys(EDocumentType)
              .filter((key) => key !== EDocumentType.invoice)
              .map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                  style={getStyles(name, documentNames, theme)}
                >
                  {camelCaseToCapitalized(name)}
                </MenuItem>
              ))
          }
        </Select>
      </FormControl>
    </Box>
  );
};
