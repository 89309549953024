import { Nullable, removeAt } from "@jamesgmarks/utilities";
import {
  Button, Card, Grid, Paper, Tab, Tabs, Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { IStatementGenerationInput, generateStatements, exportStatementsCSV } from "src/redux/features/statements/actions";
import { useAppSelector } from "src/redux/hooks";
import { StatementListContainer } from "./StatementListComponent";
import { StatementGeneratorForm, TStatementGenerationParams } from "./StatementGeneratorForm";
import { EStatementType } from "src/interfaces/ISingleViewStatementData";
import { displayFile } from "src/app-utils/display-file";
import { REACT_APP_API_ROOT_URI } from "src/App";
import { IStatement } from "src/entity-interfaces/IStatement";
import { showMessage } from "src/redux/features/messaging/actions";
import { reduceAsync } from "src/app-utils/helpers";
import { SendDocumentsModal } from "../Invoices/SendDocumentsModal";
import { EDocumentType } from "@hydra/internal";
import { TabPanel } from "src/components/parts/TabPanel";
import { SendHistoryTable } from "../SendDocuments/SendHistoryTable";

export const downloadSingleStatement = async (statementId: number, statementType: EStatementType) => {
  const url = `${REACT_APP_API_ROOT_URI}/downloads/download_statement_pdf/${statementId}`;
  const fileName = `${statementType}_statement_${statementId}`;

  await displayFile(url, fileName);
};

export const StatementsListView = () => {

  const statementsList = useAppSelector(state => state.statements.statementsList);
  const statementListMetaData = useAppSelector(state => state.statements.metaData);
  const exportLoadingState = useAppSelector(state => state.statements.exportLoadingState);

  const [ showGenerationForm, setShowGenerationForm ] = useState(true);
  const [ generateFormOptions, setGenerateFormOptions ] = useState<Nullable<TStatementGenerationParams>>(null);
  const [selectedStatements, setSelectedStatements] = useState<(IStatement & {addCsv?:boolean})[]>([]);
  const [selectAllStatementCheckboxState, setSelectAllStatementCheckboxState] = useState<boolean>(false);
  const [showSendModal, setShowSendModal] = useState(false);

  const statementDownloadQueueLength = useAppSelector(
    (state) => state.documents.downloadQueues?.[EDocumentType.statement]?.queueLength ?? 0,
  );

  const [activeTab, setActiveTab] = useState(0);

  const getSelectedStatements = () => selectedStatements;

  useEffect(() => {
    if (generateFormOptions) {
      const statementsToGenerate :IStatementGenerationInput[] = (
        (generateFormOptions.accountIds ?? []).map((accountId) => ({
          accountId,
          ...(generateFormOptions.ownershipGroupId?{ownershipGroupId: generateFormOptions.ownershipGroupId}:{}),
          statementType: EStatementType.account,
          statementStartDate: generateFormOptions.startDate,
        }))
      );

      generateStatements({statementsToGenerate});
    }
  }, [ generateFormOptions]);

  const handleGenerationForm = (options:TStatementGenerationParams) => {
    setSelectedStatements([]);
    setSelectAllStatementCheckboxState(false);
    setGenerateFormOptions(options);
  };

  const toggleAllStatementsCheckbox= (checkedValue: boolean) => {
    const selectedStatements = checkedValue ? statementsList : [];
    setSelectAllStatementCheckboxState(checkedValue);
    setSelectedStatements(selectedStatements);
  };

  const unselectIndividualStatementsCheckbox= (statement: IStatement) => {
    const indexOfItemToBeUnselected = selectedStatements.findIndex(
      (selectedStatement) => selectedStatement.id === statement.id,
    );
    const _selectedStatements = removeAt(selectedStatements, indexOfItemToBeUnselected);
    return _selectedStatements;
  };

  const toggleIndividualStatementCheckbox = (statement: IStatement, checkboxState: boolean) => {
    const _selectedStatements = checkboxState
      ? selectedStatements.concat(statement)
      : unselectIndividualStatementsCheckbox(statement);

    if (statementsList.length !== _selectedStatements.length) {
      setSelectAllStatementCheckboxState(false);
    }

    setSelectedStatements(_selectedStatements);
  };

  const downloadAllSelectedStatements = useCallback(async () => {
    const statementsSelected = statementsList.filter(statement =>
      selectedStatements.some(selectedStatement => selectedStatement.id === statement.id));

    const idsOfStatementsDownloaded = await reduceAsync<IStatement, number[]>(
      statementsSelected,
      async (acc, statement) => {
        await downloadSingleStatement(statement.id, statement.statementType);
        const downloadedIds = acc.concat(statement.id);
        return downloadedIds;
      },
      [],
    );
    const statementsNotDowloaded = statementsSelected.length !== idsOfStatementsDownloaded.length
      ? statementsSelected.filter(statement => !idsOfStatementsDownloaded.includes(statement.id))
      : [];

    if (statementsNotDowloaded.length > 0) {
      const statementIdsNotDowloaded = statementsNotDowloaded.map(statement => statement.id);
      showMessage({
        message: `Following statements not downloaded: ${statementIdsNotDowloaded}`,
        severity: 'error',
        asAlert: true,
      });
    }
  }, [statementsList, selectedStatements]);

  const downloadSelectedStatementsCsv = useCallback(async () => {
    const statementsSelected = statementsList.filter(statement =>
      selectedStatements.some(selectedStatement => selectedStatement.id === statement.id));

    const statementsToExport = statementsSelected.map(statement => {
      const clientName = (statement.billingAccount?.clients ?? [])
        .find((client) => client.isParentClient)
        ?.clientName ?? 'N/A';
      const ownershipGroupName = statement.ownershipGroup?.name ?? null;
      const fileName = `${clientName}${ownershipGroupName ? `_${ownershipGroupName}` : ''}_${statement.id}`;
      return {
        statementId: statement.id,
        fileName,
      };
    });
    await exportStatementsCSV ({exportData: statementsToExport});
  }, [statementsList, selectedStatements]);

  const handleEmailStatements = useCallback(async (addCsv: boolean) => {
    const statementsSelected = statementsList.filter(statement =>
      selectedStatements.some(selectedStatement => selectedStatement.id === statement.id));

    const documents = statementsSelected.map(statement => ({
      ...statement,
      addCsv,
    }));

    setSelectedStatements(documents);
    setShowSendModal(true);
  }, [statementsList, selectedStatements]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <>
      {
        showSendModal
            && (
              <SendDocumentsModal
                show={showSendModal}
                onHide={() => setShowSendModal(false)}
                onSend={() => setSelectedStatements([])}
                documents={getSelectedStatements().map(statement => ({
                  documentType: EDocumentType.statement,
                  ...statement,
                  accountId: `${statement.accountId}`,
                }))}

              />
            )
      }
      {/* Tabs Header */}
      <Tabs value={activeTab} onChange={handleTabChange} centered>
        <Tab label="Statement Generation" />
        <Tab label="Send History" />
      </Tabs>

      <TabPanel value={activeTab} index={0}>
        <Grid container spacing={2} mt={2} justifyContent="center" alignItems="center">
          {
            showGenerationForm &&
        <Grid item xs={10}>
          <Paper variant='outlined' sx={{padding: '1rem', backgroundColor: '#e0e0e0'}}>
            <StatementGeneratorForm
              textFieldBackgroundColor={'#e0e0e0'}
              showSearchForm={showGenerationForm}
              handleHideSearchAction={setShowGenerationForm}
              handleGeneration={handleGenerationForm}
            />
          </Paper>
        </Grid>
          }
          {
            !showGenerationForm && <Grid container spacing={1} ml={1.3} justifyContent="center" alignItems="center">
              <Grid item xs={10}  style={{ textAlign: 'center' }}>
                <Button
                  variant='contained'
                  onClick={() => { setShowGenerationForm(!showGenerationForm); }}
                  sx={{ width: '100%', verticalAlign: 'middle', my: 1 }}
                >
              Reveal Search Form
                </Button>
              </Grid>
            </Grid>
          }
          <Grid item xs={4} px={2} textAlign='center'>
            <Card
              elevation={3}
              sx={{
                p: 1,
                boxShadow: '0 0 6px rgba(35, 173, 278, 1);',
              }}
            >
              <Typography>
                    PDFs processing:&nbsp;&nbsp;
                <span style={{ fontSize: '1.2rem' }}>{`${statementDownloadQueueLength}`}</span>
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={10} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              variant='contained'
              onClick={() => downloadAllSelectedStatements()}
              disabled={selectedStatements.length === 0}
            >
          Download ({selectedStatements.length}) Statement{selectedStatements.length > 1 ? `'s`: ''}
            </Button>

            <Button
              variant='contained'
              onClick={() => downloadSelectedStatementsCsv()}
              disabled={selectedStatements.length === 0 || exportLoadingState === 'loading'}
            >
          Export ({selectedStatements.length}) Statement{selectedStatements.length > 1 ? `'s`: ''} as CSV
            </Button>

            <Button
              variant='contained'
              onClick={() => handleEmailStatements(false)}
              disabled={selectedStatements.length === 0 || exportLoadingState === 'loading'}
            >
          Email PDF Statements ({selectedStatements.length})
            </Button>

            <Button
              variant='contained'
              onClick={() => handleEmailStatements(true)}
              disabled={selectedStatements.length === 0 || exportLoadingState === 'loading'}
            >
          Email PDF Statements with CSVs ({selectedStatements.length})
            </Button>
          </Grid>
          <Grid item xs={10}>
            <StatementListContainer
              statementsList={statementsList}
              statementListMetaData={statementListMetaData}
              selectedStatements={selectedStatements}
              selectAllStatementCheckboxState={selectAllStatementCheckboxState}
              toggleAllStatementsCheckbox={toggleAllStatementsCheckbox}
              toggleIndividualStatementCheckbox={toggleIndividualStatementCheckbox}
            />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <SendHistoryTable />
      </TabPanel>
    </>
  );
};