import {
  Grid,
} from "@mui/material";
import { AutoDisableButton } from "src/components/parts/AutoDisableButton";
import { triggerActionWithResponse } from "src/redux/features/finance-control-tools/actions";
export const MarkInvoicesSentWithSendHistory = () => {

  return (
    <>
      <h3>Mark Invoices Sent With Send History</h3>
      <p>
        In the event that an invoice has been sent with send history on hydra but was not marked as sent in xero,
         this button will mark it
      </p>
      <Grid container spacing={2}>

        <Grid item xs={8}>
          <AutoDisableButton
            variant="contained"
            onClick={(e) => {
              triggerActionWithResponse('dispatchAction', {
                actionName: 'markInvoicesAsSentWithSendHistory',
                parameters: {},
              });
            }}
          > Mark Invoices Sent With Send History</AutoDisableButton>
        </Grid>
      </Grid>
    </>
  );
};
