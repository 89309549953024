// eslint-disable-next-line no-shadow
export enum ESocketEvents {
  'billingRun_updated' = 'billingRun.updated',
  'credit_note_updated' = 'credit_notes.credit_note_updated',
  'current_credit_note_pdf_generated' = 'credit_notes.current_credit_note_pdf_generated',
  'error_notification' = 'error.notification',
  'invoice_email_send_queue_updated' = 'invoices.invoice_email_send_queue_updated',
  'pdf_generate_upload_queue_updated' = 'documents.pdf_generate_upload_queue_updated',
  'invoice_updated' = 'invoices.invoice_updated',
  'invoices_updated' = 'invoices.invoices_updated',
  'notifications_updated' = 'notification.updated',
  'require_version' = 'require_version',
  'socket_initialized' = 'socket.initialized',
  'show_embed' = 'show_embed',
  'webhook_callback_updated' = 'webhook_callback.webhook_callback_updated',
}
