import React, { useEffect } from 'react';
import {
  Link,
} from "react-router-dom";
import {
  alertButtonActions,
  IMessageDescription,
} from '../../redux/features/messaging/messagingSlice';
import { closeMessage } from '../../redux/features/messaging/actions';
import {
  Alert,
  AlertTitle,
  Button,
  IconButton,
} from "@mui/material";
import { Close as CloseIcon } from '@mui/icons-material';

import { capitalize } from '../../app-utils/helpers';

type AlertVariants = 'filled' | 'outlined' | 'standard'
type IconButtonSizes = 'small' | 'medium' | 'large'

export interface IErrorMessageAlertProps {
  messageDescription: IMessageDescription,
  messageId?: string;
  showTitle?: boolean;
  size?: IconButtonSizes;
  variant?: AlertVariants;
  currentUserId?: number;
  closeTimer?: number;
}

/**
 * Displays a React BootStrap `Alert` with some parameters to control behavior, such as `severity`, `showTitle`, and `variant`.
 * Specifically, the `message` value can be either a string or *array of arrays of segment information*. The second-level arrays
 * represent lines, and each object within represents a <span />, with optional styling that can be applied during render.
 */
export const ErrorMessageAlert = ({
  messageDescription,
  messageId = Date.now().toString(),
  showTitle = true,
  variant = 'standard',
  currentUserId,
  closeTimer = 5000,
}: IErrorMessageAlertProps) => {
  useEffect(() => {
    if (!messageDescription.asAlert) {
      const timer = setTimeout(() => {
        closeMessage(messageId);
      }, closeTimer); // Automatically close after 5 seconds

      return () => clearTimeout(timer); // Cleanup timer on unmount
    }
  }, [messageId, closeTimer, messageDescription.asAlert]);

  return (
    <Alert
      variant={variant}
      severity={messageDescription.severity}
      action = {
        <>
          {
            messageDescription.actionButtonInfo?.buttonAction
              ? <Button
                color='secondary'
                disabled={!alertButtonActions[messageDescription.actionButtonInfo.buttonAction]}
                onClick={() => {
                  if (
                    messageDescription.actionButtonInfo?.buttonAction
                    && alertButtonActions[messageDescription.actionButtonInfo.buttonAction]
                  ) {
                    alertButtonActions[messageDescription.actionButtonInfo.buttonAction]({
                      ...messageDescription,
                      metaData: { timeReported: Date.now() },
                    }, currentUserId);
                  }
                }}
                sx={{
                  bottom: '0.25rem',
                  mr: 2,
                  position: 'relative',
                }}
                variant='contained'
              >{messageDescription.actionButtonInfo.actionButtonText}</Button>
              : messageDescription.actionButtonInfo?.redirectTo
                ?
                <Link
                  rel='noopener noreferrer'
                  style={{ marginRight: '1rem', marginTop: '0.35rem', minWidth: '75px' }}
                  target='_blank'
                  to={messageDescription.actionButtonInfo.redirectTo}
                >
                  {messageDescription.actionButtonInfo.actionButtonText}
                </Link>
                :
                null
          }
          <IconButton
            aria-label="close"
            color="inherit"
            onClick={(e) => closeMessage(messageId)}
            size="small"
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </>
      }
    >
      {showTitle && <AlertTitle><strong>{capitalize(messageDescription.severity ?? 'Info')}</strong></AlertTitle>}
      {
        Array.isArray(messageDescription.message)
          ? (
            messageDescription.message
              .map((line) => (
                <p style={{ margin: 0 }}>
                  {line.map(({ text, style }) => (<span style={{ ...style }}>{text}</span>))}
                </p>
              ))
          )
          : messageDescription.message
      }
    </Alert>
  );
};
