import { CreditNote, Invoice, Statement } from "../../../../../../entities/hydra";
import { TDocumentFieldGetter } from "../../../../redux/features/documents/interfaces";
import { creditNoteListFieldGetters } from "./creditNoteListFieldGetters";
import { invoiceListFieldGetters } from "./invoiceListFieldGetters";
import { statementListFieldGetters } from "./statementListFIeldGetters.tsx";

export type TTableData<T> = { name: string, getter: TDocumentFieldGetter<T> };

export const documentListFieldGetters: {
  invoice: TTableData<Invoice>[],
  creditNote: TTableData<CreditNote>[],
  statement: TTableData<Statement>[],
} = {
  invoice: invoiceListFieldGetters,
  creditNote: creditNoteListFieldGetters,
  statement: statementListFieldGetters,
};
