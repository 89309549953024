import React, { useState, useEffect, useRef } from "react";
import { Button, Box } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface DocumentEmailContentsProps {
  initialSubject?: string;
  initialBody?: string;
  onSave?: (subject: string, body: string) => void;
}

const DocumentEmailContents = ({ initialSubject = "", initialBody = "", onSave }: DocumentEmailContentsProps) => {
  const [emailSubject, setEmailSubject] = useState(initialSubject);
  const [isEditing, setIsEditing] = useState(false);
  const [htmlContent, setHtmlContent] = useState(initialBody || "");
  const quillRef = useRef<ReactQuill | null>(null);

  useEffect(() => {
    setEmailSubject(initialSubject);
    setHtmlContent(initialBody);
  }, [initialSubject, initialBody]);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      // Handle DOM changes
    });

    if (quillRef.current && quillRef.current.editor) {
      observer.observe(quillRef.current.editor.root, { childList: true, subtree: true });
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleSave = () => {
    if (onSave) {
      onSave(emailSubject, htmlContent);
    }
    setIsEditing(false);
  };

  return (
    <Box>
      {isEditing ? (
        <>
          <input
            type="text"
            value={emailSubject}
            onChange={(e) => setEmailSubject(e.target.value)}
            placeholder="Email Subject"
            style={{ width: "100%", marginBottom: "16px", padding: "8px", fontSize: "16px" }}
          />
          <ReactQuill
            ref={quillRef}
            value={htmlContent}
            onChange={setHtmlContent}
            style={{ height: "400px", marginBottom: "16px" }}
          />
          <Button variant="contained" color="primary" onClick={handleSave} sx={{ marginRight: 2 }}>
            Save
          </Button>
          <Button variant="outlined" color="secondary" onClick={() => setIsEditing(false)}>
            Cancel
          </Button>
        </>
      ) : (
        <>
          <Box style={{ marginBottom: 16 }}>
            <strong>Email Subject:</strong> {emailSubject || "(No subject)"}
          </Box>
          <Box style={{ marginBottom: 16 }}>
            <strong>Email Body:</strong>
            <div dangerouslySetInnerHTML={{ __html: htmlContent || "(No body)" }} />
          </Box>
          <Button variant="outlined" color="primary" onClick={() => setIsEditing(true)}>
            Edit
          </Button>
        </>
      )}
    </Box>
  );
};

export default DocumentEmailContents;
