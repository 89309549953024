import { Hash, HashOf, keys, Nullable } from '@jamesgmarks/utilities';
import React, { createContext, useState, useEffect } from 'react';
import { io, Socket } from 'socket.io-client';

import { ESocketEvents } from '../../../interfaces/ESocketEvents';
import { updateConnectedState } from '../../../redux/features/realtime/actions';
import { useAuth } from '../../../customHooks/useAuth';
import { REACT_APP_ROOT_URI } from '../../../App';
import {
  currentCreditNotePdfGenerated,
  requireVersion,
  setInvoiceEmailSendQueueLength,
  showSystemNotification,
  updateBillingRun,
  updateCreditNote,
  updateInvoice,
  updateInvoices,
  updateNotifications,
  showEmbedFromSocket,
  updateWebhookCallback,
  setPDFGenerateUploadQueueLengths,
} from './socket-event-handlers';

export interface IEventData {
  event: string,
  payload: unknown,
  timestamp?: number,
}

const SocketContext = createContext({} as {
  me: string,
  name: string,
  setName: React.Dispatch<React.SetStateAction<string>>,
  requiredVersion: Nullable<string>,
  eventData: IEventData[],
  updateSocketUser: () => void,
});

const customHandlers: HashOf<{ handler: (eventData: Hash) => void }> = {
  [ESocketEvents.billingRun_updated]: { handler: updateBillingRun },
  [ESocketEvents.credit_note_updated]: { handler: updateCreditNote },
  [ESocketEvents.current_credit_note_pdf_generated]: { handler: currentCreditNotePdfGenerated },
  [ESocketEvents.error_notification]: { handler: showSystemNotification },
  [ESocketEvents.invoice_email_send_queue_updated]: { handler: setInvoiceEmailSendQueueLength },
  [ESocketEvents.pdf_generate_upload_queue_updated]: { handler: setPDFGenerateUploadQueueLengths },
  [ESocketEvents.invoice_updated]: { handler: updateInvoice },
  [ESocketEvents.invoices_updated]: { handler: updateInvoices },
  [ESocketEvents.require_version]: { handler: requireVersion },
  [ESocketEvents.notifications_updated]: { handler: updateNotifications },
  [ESocketEvents.show_embed]: { handler: showEmbedFromSocket },
  [ESocketEvents.webhook_callback_updated]: { handler: updateWebhookCallback },
};

const handleEvent = ({ event, payload, timestamp }: { event: string, payload: Hash, timestamp: number }) => {
  if (keys(customHandlers).includes(event)) {
    customHandlers[event].handler(payload);
  }
};

const updateUserId = (socket: Socket, userId: number) => {
  socket.send({ type: 'set-user-id', payload: userId });
};

const ContextProvider = ({ children }: { children: React.ReactElement | string | (React.ReactElement | string)[] }) => {
  const { userId } = useAuth();

  const [me, setMe] = useState('');
  const [name, setName] = useState('');
  const [eventData, setEventData] = useState([] as IEventData[]);
  const [ requiredVersion ] = useState(null as Nullable<string>);
  const [socket, setSocket] = useState(null as null | Socket);

  useEffect(() => {
    setSocket(io(`${REACT_APP_ROOT_URI}`, {
      'reconnection': true,
      'reconnectionDelay': 1000,
      'reconnectionDelayMax': 5000,
      'reconnectionAttempts': 15,
    }));
  }, [setSocket]);

  const addEventData = (event: ESocketEvents, payload: Hash) => {
    const newEvent = {
      event,
      payload,
      timestamp: (new Date()).getTime(),
    };

    setEventData((eventData) => [
      ...eventData,
      newEvent,
    ]);

    handleEvent(newEvent);
  };

  const updateSocketUser = () => {
    socket && updateUserId(socket, userId);
  };

  useEffect(() => {
    updateSocketUser();
  }, [userId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // console.log('Preparing socket handlers.');

    socket?.on('connect_failed', () => {
      console.log('Connection Failed');
      updateConnectedState('failed');
    });
    socket?.on('connect_error', () => {
      console.log('Connection Error');
      updateConnectedState('error');
    });
    socket?.on('connect', () => {
      updateConnectedState('connected');
      updateSocketUser();
    });
    socket?.on('me', (id) => setMe(id));
    socket?.on('event', ({ event, payload }) => addEventData(event, payload));
  }, [socket]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SocketContext.Provider value={{
      me,
      name,
      setName,
      eventData,
      requiredVersion: requiredVersion,
      updateSocketUser,
    }}>
      {children}
    </SocketContext.Provider>
  );
};

export { ContextProvider, SocketContext };
