import { useState } from "react";
import {
  Grid,
  TextField,
} from "@mui/material";
import { makeYmd} from "src/app-utils";
import { triggerActionWithResponse } from "src/redux/features/finance-control-tools/actions";
import { intVal } from "@jamesgmarks/utilities";
import { AutoDisableButton } from "src/components/parts/AutoDisableButton";

export const UpdateSubscriptionStartDate = () => {

  const today = new Date();

  const [subscriptionId, setSubscriptionId] = useState<string | null >(null);

  const [startDateYMD, setStartDateYMD] = useState<string>(makeYmd(today));

  return (
    <>
      <h3>Set Subscription Start Date</h3>
      <p>If the provided sub is a listing sub with one SHB record,
        then the active date on the SHB record will be updated aswell</p>
      <Grid container spacing={2}>

        <Grid item xs={8}>
          <TextField
            id="subscription-id"
            label="Subscription ID"
            variant="outlined"
            value={subscriptionId}
            onChange={(e)=> {
              setSubscriptionId(`${intVal(e.target.value)}`);
            }}
          />
        </Grid>
        <Grid item xs={8}>
          <Grid item xs={8}>
            <TextField
              id="startDateYmd"
              label="Start Date YYYY-MM-DD"
              variant="outlined"
              value={startDateYMD}
              onChange={(e)=> {
                setStartDateYMD(`${e.target.value}`);
              }}
            />
          </Grid>

          <Grid item xs={8}>
            <AutoDisableButton
              variant="contained"
              onClick={(e) => {
                triggerActionWithResponse(
                  'updateSubscriptionStartDate',
                  {
                    subscriptionId,
                    startDateYMD,
                  },
                );
              }}
            > Set Subscription Start Date</AutoDisableButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
