import { useEffect, useState } from "react";
import { Grid, Paper } from "@mui/material";
import { useAppSelector } from "../../../../redux/hooks";
import {
  getMissingHubspotClientCount,
  updateHubspotClients,
  updateHubspotDealsForMonth,
} from "../../../../redux/features/hubspot/actions";
import { HHMonthPicker } from "../../../parts/HHMonthPicker";
import { AutoDisableButton } from "../../../parts/AutoDisableButton";

export const Hubspot = () => {
  const today = new Date();
  const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);
  const maxYear = monthStart.getFullYear();
  const maxMonth = monthStart.getMonth() + 1;
  const [dealsMonth, setDealsMonth] = useState(monthStart);

  const missingClientCount = useAppSelector((state) => state.hubspot.missingClientCount);

  useEffect(() => {
    getMissingHubspotClientCount();
  }, []);

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={10}>
        <header className="Section-header">Hubspot:</header>
      </Grid>
      <Grid item xs={10}>
        <Paper>
          <Grid container justifyContent="center" spacing={2}>
            <Grid component={Paper} item xs={5} style={{ textAlign: "center" }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <h6>Add New Clients to Hubspot</h6>
                </Grid>
                <Grid item xs={12}>
                  <h4>{missingClientCount}</h4>
                  <span>active clients not in Hubspot</span>
                </Grid>
                <Grid item xs={12}>
                  <AutoDisableButton
                    variant="contained"
                    color="primary"
                    onClick={updateHubspotClients}
                  >
                    Update
                  </AutoDisableButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid component={Paper} item xs={5} style={{ textAlign: "center" }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <h6>Add &amp; Update Deals for Month</h6>
                </Grid>
                <Grid item my={1.5} xs={12}>
                  <HHMonthPicker
                    helperText={`Between 2017-01 and ${maxYear}-${maxMonth}`}
                    label="Billing Month"
                    maxDate={monthStart}
                    minDate={new Date(2017, 0)}
                    onChange={(d) => {
                      d && setDealsMonth(d);
                    }}
                    value={dealsMonth}
                  />
                </Grid>
                <Grid item mb={2} xs={12}>
                  <AutoDisableButton
                    variant="contained"
                    color="primary"
                    onClick={() => updateHubspotDealsForMonth(dealsMonth.getFullYear(), dealsMonth.getMonth() + 1)}
                  >
                    Update
                  </AutoDisableButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
