import {
  Grid,
} from "@mui/material";
import { AutoDisableButton } from "src/components/parts/AutoDisableButton";
import { triggerActionWithResponse } from "src/redux/features/finance-control-tools/actions";
export const FlushCache = () => {
  // const charges = useAppSelector(state => state.charges.chargesList);
  return (
    <>
      <h3>Flush Cache</h3>
      <p>Flush Cache</p>

      <Grid container spacing={2}>
        <Grid item xs={8}>
          <AutoDisableButton
            variant="contained"
            onClick={async (e) => {
              e.stopPropagation();
              await new Promise<void>((resolve) => {
                triggerActionWithResponse('flushCache', {});
                resolve();
              });
            }}
          >Flush Cache</AutoDisableButton>
        </Grid>
      </Grid>
    </>
  );
};
