import React from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, 
} from '@mui/material';

interface TrackingCategoriesDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  content: React.ReactNode;
  confirmText: string;
}

const TrackingCategoriesDialog: React.FC<TrackingCategoriesDialogProps> = ({
  open,
  onClose,
  onConfirm,
  title,
  content,
  confirmText,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="secondary">
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TrackingCategoriesDialog;
