import { floatVal } from "@jamesgmarks/utilities";
import { TTableData } from ".";

import {  Statement } from "../../../../../../entities/hydra";
import { dateFromYmd, makeYmd, toDollarAmount } from "../../../../app-utils";
import { OwnershipGroups } from "@llws/typeorm-entities";

export const statementListFieldGetters: TTableData<Statement & {ownershipGroup?:OwnershipGroups}>[] = [
  { name: 'Statement ID', getter: 'id' },
  {
    name: 'Client', getter: (statement) => (
      <>
        {
          (statement.billingAccount?.clients ?? []).length === 1
            && (statement.billingAccount?.clients ?? []).map(c => c.clientName).join(', ')
        }
        {
          (statement.billingAccount?.clients ?? []).length > 1
            && (
              <>
                {(statement.billingAccount?.clients ?? []).filter(c => c.isParentClient).map(c => c.clientName).join(', ')}
                {` (${(statement.billingAccount?.clients ?? []).length} total clients)`}
              </>
            )
        }
        {
          (statement.billingAccount?.partners ?? []).length === 1
            && (statement.billingAccount?.partners ?? []).map(p => `${p.partnerName}`).join(', ')
        }
        {(statement.billingAccount?.partners ?? []).length > 1 && 'Multiple Partners'}
      </>
    ),
  },
  { name: 'Ownership Group', getter: (statement) => statement.ownershipGroup?.name  ?? 'TO BE IMPLEMENTED' },
  { name: 'Ownership Group ID', getter: (statement) => statement.ownershipGroupId },
  {
    name: 'Statement Date',
    getter: (statement) => makeYmd(dateFromYmd((statement.startDate as unknown as string).split('T')[0]), true),
  },
  { name: 'Total Outstanding', getter: (statement) => toDollarAmount(floatVal(statement.totalOutstanding)) },
];
