import { floatVal } from "@jamesgmarks/utilities";
import { Card, Table } from "react-bootstrap";
import { BaseDocument } from "./SendDocumentsModal";
import { roundFloatToCentsAsDollar } from "@hydra/internal";
import { IInvoice } from "src/entity-interfaces/IInvoice";

interface DocumentSendCardBodyProps {
  documents: BaseDocument[];
  numCheckedContacts: number;
}

const isInvoiceArray = (documents: BaseDocument[]): documents is IInvoice[] =>
  documents.every((document) =>
    document.documentType === 'invoice' &&
    'billingAccount' in document &&
    'invoiceDate' in document);

export const DocumentSendCardBody = ({
  documents,
  numCheckedContacts,
}: DocumentSendCardBodyProps) => {
  const isInvoiceDocumentArray = documents.length > 0 && isInvoiceArray(documents);

  return (
    <>
      <Card.Text><strong>Details:</strong></Card.Text>
      <Table striped bordered>
        <thead>
          <tr>
            <th>Document Count</th>
            <th>Email Count</th>
            {isInvoiceDocumentArray && <th>Subtotal</th>}
            {isInvoiceDocumentArray && <th>Taxes</th>}
            {isInvoiceDocumentArray && <th>Total</th>}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{documents.length}</td>
            <td>{numCheckedContacts}</td>
            {isInvoiceDocumentArray && (
              <>
                <td>{roundFloatToCentsAsDollar(documents.reduce((subtotal, i) => (subtotal + floatVal(i.amountInvoiced)), 0))}</td>
                <td>{roundFloatToCentsAsDollar(documents.reduce((subtotal, i) => (subtotal + floatVal(i.taxes ?? '0')), 0))}</td>
                <td>
                  {
                    roundFloatToCentsAsDollar(documents.reduce((subtotal, i) => (
                      subtotal + floatVal(i.amountInvoiced ?? '0') + floatVal(i.taxes ?? '0')
                    ), 0))
                  }
                </td>
              </>
            )}
          </tr>
        </tbody>
      </Table>
    </>
  );
};
