import React, { FC } from "react";
import {
  Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button,
} from "@mui/material";

interface AddOverrideTaxModalProps {
  open: boolean;
  onClose: () => void;
  newOverrideTax: { provinceId: string; revenueTrackingCode: string; taxName: string; taxRate: string };
  onNewOverrideTaxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onAddOverrideTax: () => void;
}

const AddOverrideTaxModal: FC<AddOverrideTaxModalProps> = (
  { open, onClose, newOverrideTax, onNewOverrideTaxChange, onAddOverrideTax },
) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Add New Override Tax Rate</DialogTitle>
    <DialogContent>
      <TextField
        label="Province ID"
        name="provinceId"
        value={newOverrideTax.provinceId}
        onChange={onNewOverrideTaxChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Revenue Tracking Code"
        name="revenueTrackingCode"
        value={newOverrideTax.revenueTrackingCode}
        onChange={onNewOverrideTaxChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Tax Name"
        name="taxName"
        value={newOverrideTax.taxName}
        onChange={onNewOverrideTaxChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Tax Rate"
        name="taxRate"
        value={newOverrideTax.taxRate}
        onChange={onNewOverrideTaxChange}
        fullWidth
        margin="normal"
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={onAddOverrideTax} color="primary">Add</Button>
    </DialogActions>
  </Dialog>
);

export default AddOverrideTaxModal;
