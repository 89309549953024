import { useState } from "react";
import {
  Grid,
  TextField,
} from "@mui/material";
import { intVal } from "@jamesgmarks/utilities";
import { triggerActionWithResponse } from "src/redux/features/finance-control-tools/actions";
import { AutoDisableButton } from "src/components/parts/AutoDisableButton";
export const AddCreditToXero = () => {

  const [creditAppliedId, setCreditAppliedId] = useState<string>('');

  return (
    <>
      <h3>Add Credit to Xero</h3>
      <p>In the event that the credit upload to xero fails. we can retry it here.</p>
      <p>Please use the credit applied Id from the DB here.</p>
      <Grid container spacing={2}>

        <Grid item xs={8}>
          <TextField
            id="credit-applied-id"
            label="Credit Applied Id"
            variant="outlined"
            value={creditAppliedId}
            onChange={(e)=> {
              if(e.target.value===''){
                setCreditAppliedId(e.target.value);
                return;
              }
              const parsedVal = intVal(e.target.value);
              if(isNaN(parsedVal)){
                return;
              }
              setCreditAppliedId(`${parsedVal}`);
            }}
          />
        </Grid>

        <Grid item xs={8}>
          <AutoDisableButton
            variant="contained"
            onClick={(e) => {
              triggerActionWithResponse('addCreditToXero', {
                creditAppliedId,
              });
            }}
          >Add Credit to Xero</AutoDisableButton>
        </Grid>
      </Grid>
    </>
  );
};
