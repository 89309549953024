import { Nullable } from '@jamesgmarks/utilities';
import { createSlice } from '@reduxjs/toolkit';
import { IStatement } from 'src/entity-interfaces/IStatement';
import { ISingleViewStatementData } from 'src/interfaces/ISingleViewStatementData';
import { TLoadedState } from '../../../types/TLoadedState';
import { IActionType, IApiQueryListResponse, IApiQuerySingularResponse } from '../../utils';
import { IGenerateStatementActionResponse } from './actions';

interface IStatementState {
  loadedState: TLoadedState,
  statementsList: IStatement[],
  currentStatement: Nullable<IStatement>,
  singleViewStatementData: Nullable<ISingleViewStatementData>
  metaData: Nullable<IApiQueryListResponse<IStatement>['meta']>
  exportLoadingState: TLoadedState,

};

const initialStatementState: IStatementState = {
  loadedState: 'idle',
  statementsList: [],
  currentStatement: null,
  singleViewStatementData: null,
  metaData: null,
  exportLoadingState: 'idle',
};

export const statementSlice = createSlice({
  name: 'statementSlice',
  initialState: { ...initialStatementState },
  reducers: {
    statementListReceived: (state, action: IActionType<IApiQuerySingularResponse<IGenerateStatementActionResponse>>) => {
      state.statementsList = action.payload.data.actionResponse;
      state.metaData = action.payload.meta;
    },
    currentStatementReceived: (state, action: IActionType<IApiQuerySingularResponse<IStatement>>) => {
      state.currentStatement = action.payload.data;
    },
    singleViewStatementDataReceived: (
      state,
      action: IActionType<IApiQuerySingularResponse<ISingleViewStatementData>>,
    ) => {
      state.singleViewStatementData = action.payload.data;
    },
    clearStatementList: (state) => {
      state.statementsList = [];
      state.metaData = null;
    },
    clearCurrentStatement: (state) => {
      state.currentStatement = null;
    },
    clearSingleViewStatementData: (state) => {
      state.singleViewStatementData = null;
    },
    clearStatementState: (state) => {
      state = { ...initialStatementState };
    },
    setLoadedState: (state, action: IActionType<TLoadedState>) => {
      state.loadedState = action.payload;
    },
    setExportLoadingState: (state, action: IActionType<TLoadedState>) => {
      state.exportLoadingState = action.payload;
    },

  },
});

// Action creators are generated for each case reducer function
export const {
  statementListReceived,
  currentStatementReceived,
  singleViewStatementDataReceived,
  clearStatementList,
  clearCurrentStatement,
  clearSingleViewStatementData,
  clearStatementState,
  setLoadedState,
  setExportLoadingState,
} = statementSlice.actions;

export default statementSlice.reducer;