import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  TablePagination,
} from "@mui/material";
import { TaxByProvince } from "../../../../../entities/hydra";

interface TaxRatesTableProps {
  taxRates: TaxByProvince[];
  showDeleted: boolean;
  sortColumnMain: string;
  sortDirectionMain: "ASC" | "DESC";
  handleSortMain: (column: string) => void;
  handleOpenConfirmModal: (id: number, isOverride: boolean) => void;
  handleOpenTaxModal: () => void;
  totalRecords: number;
  mainPage: number;
  mainRowsPerPage: number;
  handleMainPageChange: (event: unknown, newPage: number) => void;
  handleMainRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const TaxRatesTable: React.FC<TaxRatesTableProps> = ({
  taxRates,
  showDeleted,
  sortColumnMain,
  sortDirectionMain,
  handleSortMain,
  handleOpenConfirmModal,
  handleOpenTaxModal,
  totalRecords,
  mainPage,
  mainRowsPerPage,
  handleMainPageChange,
  handleMainRowsPerPageChange,
}) => {
  return (
    <Paper sx={{ padding: 2 }}>
      <Typography variant="h6" sx={{ display: 'inline-block' }}>Main Tax Rates</Typography>
      <Button variant="contained" onClick={handleOpenTaxModal} sx={{ float: 'right', margin: 1 }}>
        Add Tax Rate
      </Button>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell onClick={() => handleSortMain("gp.provinceName")}>
                Province Name {sortColumnMain === "gp.provinceName" ? (sortDirectionMain === "ASC" ? "↑" : "↓") : ""}
              </TableCell>
              <TableCell onClick={() => handleSortMain("taxName")}>
                Tax Name {sortColumnMain === "taxName" ? (sortDirectionMain === "ASC" ? "↑" : "↓") : ""}
              </TableCell>
              <TableCell onClick={() => handleSortMain("taxRate")}>
                Tax Rate {sortColumnMain === "taxRate" ? (sortDirectionMain === "ASC" ? "↑" : "↓") : ""}
              </TableCell>
              {showDeleted && (
                <>
                  <TableCell onClick={() => handleSortMain("effectiveFrom")}>
                    Effective From {sortColumnMain === "effectiveFrom" ? (sortDirectionMain === "ASC" ? "↑" : "↓") : ""}
                  </TableCell>
                  <TableCell onClick={() => handleSortMain("effectiveUntil")}>
                    Effective Until {sortColumnMain === "effectiveUntil" ? (sortDirectionMain === "ASC" ? "↑" : "↓") : ""}
                  </TableCell>
                  <TableCell onClick={() => handleSortMain("hydraState")}>
                    Hydra State {sortColumnMain === "hydraState" ? (sortDirectionMain === "ASC" ? "↑" : "↓") : ""}
                  </TableCell>
                </>
              )}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {taxRates.map((tax) => (
              <TableRow key={tax.id}>
                <TableCell>{tax.geoProvince.provinceName}</TableCell>
                <TableCell>{tax.taxName}</TableCell>
                <TableCell>{tax.taxRate}</TableCell>
                {showDeleted && (
                  <>
                    <TableCell>{tax.effectiveFrom}</TableCell>
                    <TableCell>{tax.effectiveUntil}</TableCell>
                    <TableCell>{tax.hydraState}</TableCell>
                  </>
                )}
                <TableCell>
                  <Button color="error" onClick={() => handleOpenConfirmModal(tax.id, false)}>Delete</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalRecords}
        page={mainPage}
        onPageChange={handleMainPageChange}
        rowsPerPage={mainRowsPerPage}
        onRowsPerPageChange={handleMainRowsPerPageChange}
      />
    </Paper>
  );
};

export default TaxRatesTable;
