import { useEffect, useState, useCallback, useRef } from "react";
import {
  Grid,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { createOne, getMany, updateOne } from "src/redux/features/dynamic-hydra/actions";
import { TaxByProvince, TaxByProvinceOverride } from "../../../../../entities/hydra";
import { useAppSelector } from "src/redux/hooks";
import { INestedWhereCommand } from "@llws/dynamic-router-utils";
import TaxRatesTable from "./TaxRatesTable";
import OverrideTaxRatesTable from "./OverrideTaxRatesTable";
import { makeYmd } from "src/app-utils";
import AddTaxModal from "./AddTaxModal";
import AddOverrideTaxModal from "./AddOverrideTaxModal";
import ConfirmDeleteModal from "./ConfirmDeleteModal";

const TaxRatesManager = () => {
  const [taxRates, setTaxRates] = useState<TaxByProvince[]>([]);
  const [overrideRates, setOverrideRates] = useState<TaxByProvinceOverride[]>([]);
  const [openTaxModal, setOpenTaxModal] = useState(false);
  const [openOverrideTaxModal, setOpenOverrideTaxModal] = useState(false);
  const [newTax, setNewTax] = useState({ provinceId: "", taxName: "", taxRate: "" });
  const [newOverrideTax, setNewOverrideTax] = useState(
    { provinceId: "", revenueTrackingCode: "", taxName: "", taxRate: "" },
  );
  const [sortColumnMain, setSortColumnMain] = useState<string>("id");
  const [sortDirectionMain, setSortDirectionMain] = useState<"ASC" | "DESC">("ASC");
  const [sortColumnOverride, setSortColumnOverride] = useState<string>("id");
  const [sortDirectionOverride, setSortDirectionOverride] = useState<"ASC" | "DESC">("ASC");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [deleteTaxId, setDeleteTaxId] = useState<number | null>(null);
  const [deleteOverrideTaxId, setDeleteOverrideTaxId] = useState<number | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [showDeleted, setShowDeleted] = useState(false);

  const [mainPage, setMainPage] = useState(0);
  const [mainRowsPerPage, setMainRowsPerPage] = useState(10);
  const totalMainRecords = useAppSelector(state => state.dynamicHydra.metaData?.totalRecords ?? 0);

  const [overridePage, setOverridePage] = useState(0);
  const [overrideRowsPerPage, setOverrideRowsPerPage] = useState(10);
  const totalOverrideRecords = useAppSelector(state => state.dynamicHydra.metaData?.totalRecords ?? 0);

  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>(searchQuery);
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [effectiveUntilDate, setEffectiveUntilDate] = useState<Date | null>(null);
  const [tabIndex, setTabIndex] = useState(0);
  const userId = useAppSelector(state => state.auth?.decodedToken?.userId);

  const handleSortMain = (column: string) => {
    const isAsc = sortColumnMain === column && sortDirectionMain === "ASC";
    setSortDirectionMain(isAsc ? "DESC" : "ASC");
    setSortColumnMain(column);
    fetchTaxRates();
  };

  const handleSortOverride = (column: string) => {
    const isAsc = sortColumnOverride === column && sortDirectionOverride === "ASC";
    setSortDirectionOverride(isAsc ? "DESC" : "ASC");
    setSortColumnOverride(column);
    fetchOverrideRates();
  };

  const handleMainPageChange = (event: unknown, newPage: number) => {
    setMainPage(newPage);
    fetchTaxRates(newPage, mainRowsPerPage);
  };

  const handleMainRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMainRowsPerPage(parseInt(event.target.value, 10));
    setMainPage(0);
    fetchTaxRates(0, parseInt(event.target.value, 10));
  };

  const handleOverridePageChange = (event: unknown, newPage: number) => {
    setOverridePage(newPage);
    fetchOverrideRates(newPage, overrideRowsPerPage);
  };

  const handleOverrideRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOverrideRowsPerPage(parseInt(event.target.value, 10));
    setOverridePage(0);
    fetchOverrideRates(0, parseInt(event.target.value, 10));
  };

  const fetchTaxRates = useCallback(async (
    page = mainPage, rowsPerPage = mainRowsPerPage, query = debouncedSearchQuery,
  ) => {
    const hydraStates = showDeleted ? ['active', 'deleted'] : ['active'];
    const whereCommands:INestedWhereCommand[] = [
      {
        condition: 'and',
        whereCommands: [
          { field: 't.hydraState', value: hydraStates.join('|'), operator: 'in' },
        ],
      },
      {
        condition: 'and',
        whereCommands: [
          { field: 'gp.provinceName', value: `${query}`, operator: 'like', condition: 'or' },
          { field: 't.taxName', value: `${query}`, operator: 'like', condition: 'or' },
          { field: 't.taxRate', value: `${query}`, operator: 'like', condition: 'or' },
        ],
      },
    ];

    const data = await getMany("taxes_by_province", {
      alias: 't',
      relations: [{ path: 't.geoProvince', alias: 'gp' }],
      orderBy: [{ field: sortColumnMain, direction: sortDirectionMain.toLowerCase() === "asc" ? "asc" : "desc" }],
      limit: rowsPerPage,
      page: page + 1,
      where: whereCommands,
    });
    const filteredData = data.filter((item): item is TaxByProvince => "provinceId" in item && "taxName" in item);
    setTaxRates(filteredData);
  }, [sortColumnMain, sortDirectionMain, debouncedSearchQuery, showDeleted, mainPage, mainRowsPerPage]);

  const fetchOverrideRates = useCallback(async (
    page = overridePage, rowsPerPage = overrideRowsPerPage, query = debouncedSearchQuery,
  ) => {
    const hydraStates = showDeleted ? ['active', 'deleted'] : ['active'];

    const whereCommands:INestedWhereCommand[] =  [
      {
        condition: 'and',
        whereCommands: [
          { field: 'to.hydraState', value: hydraStates.join('|'), operator: 'in' },
        ],
      },
      {
        condition: 'and',
        whereCommands: [
          { field: 'gp.provinceName', value: `${query}`, operator: 'like', condition: 'or' },
          { field: 'to.revenueTrackingCode', value: `${query}`, operator: 'like', condition: 'or' },
          { field: 'to.taxName', value: `${query}`, operator: 'like', condition: 'or' },
          { field: 'to.taxRate', value: `${query}`, operator: 'like', condition: 'or' },
        ],
      },
    ];

    const data = await getMany("taxes_by_province_override", {
      alias: 'to',
      relations: [{ path: 'to.geoProvince', alias: 'gp' }],
      orderBy: [
        { field: sortColumnOverride, direction: sortDirectionOverride.toLowerCase() === "asc" ? "asc" : "desc" },
      ],
      limit: rowsPerPage,
      page: page + 1,
      where: whereCommands,
    });
    const filteredData = data.filter((item): item is TaxByProvinceOverride => "revenueTrackingCode" in item);
    setOverrideRates(filteredData);
  }, [sortColumnOverride, sortDirectionOverride, debouncedSearchQuery, showDeleted, overridePage, overrideRowsPerPage]);

  const handleOpenConfirmModal = (id: number, isOverride: boolean) => {
    if (isOverride) {
      setDeleteOverrideTaxId(id);
    } else {
      setDeleteTaxId(id);
    }
    setOpenConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
    setDeleteTaxId(null);
    setDeleteOverrideTaxId(null);
  };

  const handleConfirmDelete = async () => {
    if (deleteTaxId !== null && effectiveUntilDate !== null) {
      await updateOne("taxes_by_province", JSON.stringify({
        id: deleteTaxId,
        hydraState: 'deleted',
        effectiveUntil: makeYmd(effectiveUntilDate),
        modifiedUserId: userId,
      }), deleteTaxId);
      fetchTaxRates();
    } else if (deleteOverrideTaxId !== null && effectiveUntilDate !== null) {
      await updateOne("taxes_by_province_override", JSON.stringify({
        id: deleteOverrideTaxId,
        hydraState: 'deleted',
        effectiveUntil: makeYmd(effectiveUntilDate),
        modifiedUserId: userId,
      }), deleteOverrideTaxId);
      fetchOverrideRates();
    }
    handleCloseConfirmModal();
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(() => {
      setDebouncedSearchQuery(event.target.value);
    }, 300);
  };

  const handleShowDeletedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowDeleted(event.target.checked);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const filteredTaxRates = taxRates.filter((tax) =>
    tax.geoProvince.provinceName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    tax.taxName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    tax.taxRate.toString().includes(searchQuery));

  const filteredOverrideRates = overrideRates.filter((tax) =>
    tax.geoProvince.provinceName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    tax.revenueTrackingCode.toLowerCase().includes(searchQuery.toLowerCase()) ||
    tax.taxName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    tax.taxRate.toString().includes(searchQuery));

  const handleOpenTaxModal = () => {
    setNewTax({ provinceId: "", taxName: "", taxRate: "" });
    setOpenTaxModal(true);
  };

  const handleCloseTaxModal = () => {
    setOpenTaxModal(false);
  };

  const handleNewTaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setNewTax((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddTax = async () => {
    await createOne("taxes_by_province", JSON.stringify({
      ...newTax,
      createdUserId: userId,
    }));
    fetchTaxRates();
    handleCloseTaxModal();
  };

  const handleOpenOverrideTaxModal = () => {
    setNewOverrideTax({ provinceId: "", revenueTrackingCode: "", taxName: "", taxRate: "" });
    setOpenOverrideTaxModal(true);
  };

  const handleCloseOverrideTaxModal = () => {
    setOpenOverrideTaxModal(false);
  };

  const handleNewOverrideTaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setNewOverrideTax((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddOverrideTax = async () => {
    await createOne("taxes_by_province_override", JSON.stringify({
      ...newOverrideTax,
      createdUserId: userId,
    }));
    fetchOverrideRates();
    handleCloseOverrideTaxModal();
  };

  useEffect(() => {
    if (tabIndex === 0) {
      fetchTaxRates();
    } else {
      fetchOverrideRates();
    }
  }, [fetchTaxRates, fetchOverrideRates, debouncedSearchQuery, tabIndex, showDeleted]);

  return (
    <Grid container spacing={4} justifyContent="center" alignItems="flex-start" sx={{ padding: 4 }}>
      <Grid item xs={12} textAlign="center">
        <Typography variant="h4" sx={{ marginBottom: 2 }}>Tax Rates Manager</Typography>
        <TextField
          label="Search"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearchChange}
          sx={{ margin: 1, width: '300px' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            style: {
              borderRadius: '25px',
            },
          }}
        />
        <FormControlLabel
          control={<Checkbox checked={showDeleted} onChange={handleShowDeletedChange} />}
          label="Show Deleted Records"
          sx={{ display: 'block', marginTop: 2 }}
        />
      </Grid>
      <Grid item xs={12}>
        <Tabs value={tabIndex} onChange={handleTabChange} centered>
          <Tab label="Tax Rates" />
          <Tab label="Override Tax Rates" />
        </Tabs>
        <Box hidden={tabIndex !== 0}>
          <TaxRatesTable
            taxRates={filteredTaxRates}
            showDeleted={showDeleted}
            sortColumnMain={sortColumnMain}
            sortDirectionMain={sortDirectionMain}
            handleSortMain={handleSortMain}
            handleOpenConfirmModal={handleOpenConfirmModal}
            handleOpenTaxModal={handleOpenTaxModal}
            totalRecords={totalMainRecords}
            mainPage={mainPage}
            mainRowsPerPage={mainRowsPerPage}
            handleMainPageChange={handleMainPageChange}
            handleMainRowsPerPageChange={handleMainRowsPerPageChange}
          />
        </Box>
        <Box hidden={tabIndex !== 1}>
          <OverrideTaxRatesTable
            overrideRates={filteredOverrideRates}
            showDeleted={showDeleted}
            sortColumnOverride={sortColumnOverride}
            sortDirectionOverride={sortDirectionOverride}
            handleSortOverride={handleSortOverride}
            handleOpenConfirmModal={handleOpenConfirmModal}
            totalRecords={totalOverrideRecords}
            overridePage={overridePage}
            overrideRowsPerPage={overrideRowsPerPage}
            handleOverridePageChange={handleOverridePageChange}
            handleOverrideRowsPerPageChange={handleOverrideRowsPerPageChange}
            handleOpenOverrideTaxModal={handleOpenOverrideTaxModal}
          />
        </Box>
      </Grid>
      <AddTaxModal
        open={openTaxModal}
        onClose={handleCloseTaxModal}
        newTax={newTax}
        onNewTaxChange={handleNewTaxChange}
        onAddTax={handleAddTax}
      />
      <AddOverrideTaxModal
        open={openOverrideTaxModal}
        onClose={handleCloseOverrideTaxModal}
        newOverrideTax={newOverrideTax}
        onNewOverrideTaxChange={handleNewOverrideTaxChange}
        onAddOverrideTax={handleAddOverrideTax}
      />
      <ConfirmDeleteModal
        open={openConfirmModal}
        onClose={handleCloseConfirmModal}
        onConfirmDelete={handleConfirmDelete}
        effectiveUntilDate={effectiveUntilDate}
        setEffectiveUntilDate={setEffectiveUntilDate}
      />
    </Grid>
  );
};

export default TaxRatesManager;