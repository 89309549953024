import { first, HashOf, strVal } from "@jamesgmarks/utilities";
import { Grid, IconButton, Button } from "@mui/material"; // Add Button import
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Card, Col, FormCheck, ListGroup, Row } from "react-bootstrap";
import { isIInvoiceArray } from "../../../entity-interfaces/IInvoice";
import { ILiftClient } from "../../../entity-interfaces/ILiftClient";
import ClearIcon from '@mui/icons-material/Clear';
import { IClientHasBillingContacts, IOwnershipGroups } from "@llws/lift-entity-interfaces";
import { BaseDocument } from "./SendDocumentsModal";
import { DocumentSendCardBody } from "./DocumentSendCardBody";
import DocumentEmailContents from "./DocumentEmailContents";

export const getDefaultContactsForGroup = (
  client: ILiftClient,
  ownershipGroup?: IOwnershipGroups,
): IClientHasBillingContacts[] => {
  const ogContacts = (
    (client.clientHasBillingContacts ?? []).filter((chbc) => chbc.ownershipGroupId === (ownershipGroup?.id ?? null))
  );

  return (
    ogContacts.length > 0
      ? ogContacts
      : (client.clientHasBillingContacts ?? []).filter((chbc) => !chbc.ownershipGroupId)
  );
};

interface IDocumentGroupSectionProps {
  documents: BaseDocument[];
  ownershipGroup?: IOwnershipGroups;
  groupKey: string;
  emailSubject?: string;
  emailBody?: string;
  setRemovedSendCards: Dispatch<SetStateAction<string[]>>;
  setSelectedContactsForGroup: (groupKey: string, contacts: number[]) => void;
  setSelectedEmailContentsForGroup: (groupKey: string, subject: string, body: string) => void;
  setGroupEmailContents: Dispatch<SetStateAction<HashOf<{ subject: string, body: string }>>>;
  groupEmailContents: HashOf<{ subject: string, body: string }>;
}

export const DocumentGroupSection = ({
  documents,
  ownershipGroup,
  groupKey,
  emailSubject: initialEmailSubject,
  emailBody: initialEmailBody,
  setRemovedSendCards,
  setSelectedContactsForGroup,
  setSelectedEmailContentsForGroup,
  setGroupEmailContents,
  groupEmailContents,
}: IDocumentGroupSectionProps) => {

  const emailSubject = groupEmailContents[groupKey]?.subject || initialEmailSubject || `New ${isIInvoiceArray(documents) ? 'Invoices' : 'Credit Notes'} From Rentsync (Landlord Web Solutions)`;
  const emailBody = groupEmailContents[groupKey]?.body || initialEmailBody || '';

  const clients = first(documents)!.billingAccount?.clients;
  const client = first(clients!)!;

  const billingContacts = getDefaultContactsForGroup(client, ownershipGroup);

  const [checkedContacts, setCheckedContacts] = useState([] as number[]);
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    setCheckedContacts(
      (billingContacts ?? [])
        .filter(c => c.receivesInvoices)
        .map(c => c.billingContact!.id! ?? null),
    );
  }, [setCheckedContacts]); // eslint-disable-line react-hooks/exhaustive-deps

  const setContactChecked = (contactId: number, checked: boolean = true) => {
    const newContacts = [
      ...(checkedContacts.filter((cId) => cId !== contactId)),
      ...(checked ? [contactId] : []),
    ];

    setCheckedContacts(newContacts);
    setSelectedContactsForGroup(groupKey, newContacts);
  };

  const documentsDescriptor = isIInvoiceArray(documents) ? 'Invoices' : 'Credit Notes';
  const datePlaceholder = isIInvoiceArray(documents) ? '{{INVOICE_DATE}}' : '{{CREDIT_NOTE_DATE}}';

  const transitionTimeout = 400;

  return (
    <Row>
      <Col xs={12}>
        <Grid
          container
          alignItems='center'
          sx={{
            opacity,
            transition: `opacity ${transitionTimeout}ms ease-in`,
            WebkitTransition: `opacity ${transitionTimeout}ms ease-in`,
          }}
        >
          <Grid item xs={1}>
            <IconButton
              onClick={
                () => {
                  setOpacity(0);

                  setTimeout(() => {
                    setRemovedSendCards((old) => [...old, strVal(groupKey)]);
                  }, transitionTimeout);
                }
              }
              sx={{
                color: '#fff',
                backgroundColor: '#fd0100',
                '&:hover': {
                  backgroundColor: '#fd0100',
                  opacity: '0.75',
                },
              }}
            >
              <ClearIcon>Remove</ClearIcon>
            </IconButton>
          </Grid>
          <Grid item xs>
            <Card>
              <Card.Header>
                <Card.Title>
                  {
                    ownershipGroup?.name
                      ? `Ownership Group: [${ownershipGroup.id}] ${ownershipGroup?.name} (${client?.clientName})`
                      : client?.clientName ?? 'ISSUE'
                  }
                </Card.Title>
                <Card.Subtitle>
                  <DocumentEmailContents
                    initialSubject={emailSubject}
                    initialBody={emailBody}
                    onSave={(subject, body) => {
                      setSelectedEmailContentsForGroup(groupKey, subject, body);
                    }}
                  />
                  <Button
                    onClick={() => {
                      setGroupEmailContents((prev) => {
                        const newContents = { ...prev };
                        Object.keys(newContents).forEach((key) => {
                          newContents[key] = { subject: emailSubject, body: emailBody };
                        });
                        return newContents;
                      });
                    }}
                  >
                    Copy Email Subject and Body to All
                  </Button>
                </Card.Subtitle>
              </Card.Header>
              <Card.Body>

                <hr />
                <Card.Text><strong>Send To:</strong></Card.Text>
                <ListGroup variant="flush">
                  {
                    (billingContacts ?? [])
                      .slice()
                      .sort((a, b) => a.receivesInvoices > b.receivesInvoices ? -1 : 1)
                      .map((contactInfo, i) => {
                        const billingContactId = contactInfo?.billingContact?.id ?? -1;
                        const contactChecked = checkedContacts.includes(billingContactId);

                        return (
                          <ListGroup.Item
                            key={`${contactInfo.id ?? i}`}
                            action
                            variant={contactChecked ? 'primary' : (checkedContacts.length === 0 ? 'danger' : 'light')}
                            onClick={e => setContactChecked(billingContactId, !contactChecked)}
                          >
                            <FormCheck
                              checked={contactChecked}
                              label={
                                `${contactInfo.billingContact?.name ?? ''} (${contactInfo.billingContact?.email ?? ''})`
                              }
                              readOnly
                            />
                          </ListGroup.Item>
                        );
                      })
                  }
                </ListGroup>

                <hr />
                <DocumentSendCardBody
                  documents={documents}
                  numCheckedContacts={checkedContacts.length}
                />
              </Card.Body>
            </Card>
          </Grid>
        </Grid>
      </Col>
    </Row>
  );
};
