import React, { FC } from "react";
import {
  Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button,
} from "@mui/material";

interface AddTaxModalProps {
  open: boolean;
  onClose: () => void;
  newTax: { provinceId: string; taxName: string; taxRate: string };
  onNewTaxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onAddTax: () => void;
}

const AddTaxModal: FC<AddTaxModalProps> = ({ open, onClose, newTax, onNewTaxChange, onAddTax }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Add New Tax Rate</DialogTitle>
    <DialogContent>
      <TextField
        label="Province ID"
        name="provinceId"
        value={newTax.provinceId}
        onChange={onNewTaxChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Tax Name"
        name="taxName"
        value={newTax.taxName}
        onChange={onNewTaxChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Tax Rate"
        name="taxRate"
        value={newTax.taxRate}
        onChange={onNewTaxChange}
        fullWidth
        margin="normal"
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={onAddTax} color="primary">Add</Button>
    </DialogActions>
  </Dialog>
);

export default AddTaxModal;
