import querystring from 'querystring';

import { dispatch, store } from "../../store";
import { IDocumentMap } from "./interfaces";
import { EDocumentsLoadedState, loadReceivedDocuments, setLoadedState, loadEmailBodies } from "./documentsSlice";
import { REACT_APP_API_ROOT_URI } from "../../../App";
import { typedApiFetch } from "../../utils";
import { BaseDocument } from 'src/components/pages/Invoices/SendDocumentsModal';
import { showMessage } from '../messaging/actions';
import { performApiActionRequest, TActionResponse } from 'src/redux/apiActionRequest';
import { EmailData } from 'src/interfaces/EmailData';
import { TDocumentName } from 'src/types/documents';

export const getDocuments = async (
  isoStartDate: string,
  isoEndDate: string,
  documentNames: TDocumentName[],
  billingAccountIds: number[],
) => {
  dispatch(setLoadedState(EDocumentsLoadedState.loading));

  const parsedQuery = querystring.stringify({
    isoStartDate,
    isoEndDate,
    documentNames: documentNames.map(name => name.toString()),
    billingAccountIds,
  });

  const url = `${REACT_APP_API_ROOT_URI}/documents?${parsedQuery}`;

  const response = await typedApiFetch<IDocumentMap>(url);

  const documentsMap = await response.json();

  dispatch(loadReceivedDocuments(documentsMap));

  dispatch(setLoadedState(EDocumentsLoadedState.loaded));
};

interface IDocumentNotification {
  toBillingContactIds: number[];
  documents: BaseDocument[];
  subject?: string;
  body?: string;
}

export const sendMultipleDocumentNotifications = async (
  notifications: IDocumentNotification[],
) => {
  const systemInfo = store.getState().systemInfo;

  dispatch(setLoadedState(EDocumentsLoadedState.loading));

  const outNotifications = notifications.reduce((acc, n) => {

    if (!n.toBillingContactIds || n.toBillingContactIds.length === 0) {
      showMessage({ message: 'Must select billing contacts.' });
      throw new Error('Must select billing contacts');
    }

    return [
      ...acc,
      ...(n.toBillingContactIds.map(c => ({
        to: c,
        subject: n.subject,
        body: n.body,
        documents: n.documents,
        isDevelopment: systemInfo.env.NODE_ENV !== 'production',
      }))),
    ];
  }, [] as ({ to: number,  documents: BaseDocument[], isDevelopment: boolean })[]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const response = await performApiActionRequest<TActionResponse<{ to: EmailData, success: boolean }[]>>(
    'triggerDocumentNotifications',
    { notifications: outNotifications },
  );

  dispatch(setLoadedState(EDocumentsLoadedState.sent));
  showMessage({ message: 'Invoices sent', severity: 'success' });

  setTimeout(() => { setLoadedState(EDocumentsLoadedState.idle); }, 5000);
};

export const fetchEmailBodies = async () => {
  const url = `${REACT_APP_API_ROOT_URI}/documents/default_email_notification_templates`;

  const response = await typedApiFetch<Record<string, string>>(url);

  const emailBodies = await response.json();

  dispatch(loadEmailBodies(emailBodies));
};

