import { OwnershipGroups } from "@llws/typeorm-entities";
import { PdfGeneration, Statement, StatementLine, StatementRaw } from "../../../entities/hydra";
import { IBillingAccount } from "./IBillingAccount";
import { Nullable } from "@jamesgmarks/utilities";
import { INoteComment } from "@llws/hydra-shared";
import { EStatementType } from "src/interfaces/ISingleViewStatementData";
import { EStatementHydraState } from "../../../entities/hydra/Statement";
import { IOwnershipGroups } from "@llws/lift-entity-interfaces";

export interface IStatement {
  id: number;
  accountId?: number;
  ownershipGroupId?: Nullable<number>;
  balanceForward?: string;
  totalOutstanding?: Nullable<string>;
  startDate?: Date;
  notes?: INoteComment[];
  originalAddress?: string;
  addressOverride?: Nullable<string>;
  generationTimestamp: Date;
  generationType?: EStatementGenerationType;
  statementType: EStatementType;
  hydraState?: EStatementHydraState;
  created?: Date;
  createdUserId?: number;
  modified?: Nullable<Date>;
  modifiedUserId?: Nullable<number>;

  // Relationships
  statementRaw?: StatementRaw;
  billingAccount: IBillingAccount;  // Custom type for billingAccount
  statementLines?: StatementLine[];
  pdfGenerations?: PdfGeneration[];

  // Custom addition (if needed for ownership group)
  ownershipGroup?: IOwnershipGroups;
}

// # Duplicate type
export enum EStatementGenerationType {
  client = 'client',
  group = 'group',
  monthly = 'monthly',
  manual = 'manual',
  manualFull = 'manual_full',
  manualOg = 'manual_og'
}