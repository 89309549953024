import React, { useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  TablePagination,
} from "@mui/material";
import { TaxByProvinceOverride } from "../../../../../entities/hydra";

interface OverrideTaxRatesTableProps {
  overrideRates: TaxByProvinceOverride[];
  showDeleted: boolean;
  sortColumnOverride: string;
  sortDirectionOverride: "ASC" | "DESC";
  handleSortOverride: (column: string) => void;
  handleOpenConfirmModal: (id: number, isOverride: boolean) => void;
  totalRecords: number;
  overridePage: number;
  overrideRowsPerPage: number;
  handleOverridePageChange: (event: unknown, newPage: number) => void;
  handleOverrideRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleOpenOverrideTaxModal: () => void;
}

const OverrideTaxRatesTable: React.FC<OverrideTaxRatesTableProps> = ({
  overrideRates,
  showDeleted,
  sortColumnOverride,
  sortDirectionOverride,
  handleSortOverride,
  handleOpenConfirmModal,
  totalRecords,
  overridePage,
  overrideRowsPerPage,
  handleOverridePageChange,
  handleOverrideRowsPerPageChange,
  handleOpenOverrideTaxModal,
}) => {
  const [page, setPage] = useState(overridePage);
  const [rowsPerPage, setRowsPerPage] = useState(overrideRowsPerPage);

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    handleOverridePageChange(event, newPage);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    handleOverrideRowsPerPageChange(event);
  };

  return (
    <Paper sx={{ padding: 2 }}>
      <Typography variant="h6" sx={{ display: 'inline-block' }}>Override Tax Rates</Typography>
      <Button variant="contained" onClick={handleOpenOverrideTaxModal} sx={{ float: 'right', margin: 1 }}>
        Add Override Tax Rate
      </Button>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell onClick={() => handleSortOverride("gp.provinceName")}>
                Province Name {sortColumnOverride === "gp.provinceName" ? (sortDirectionOverride === "ASC" ? "↑" : "↓") : ""}
              </TableCell>
              <TableCell onClick={() => handleSortOverride("revenueTrackingCode")}>
                Revenue Tracking Code {sortColumnOverride === "revenueTrackingCode" ? (sortDirectionOverride === "ASC" ? "↑" : "↓") : ""}
              </TableCell>
              <TableCell onClick={() => handleSortOverride("taxName")}>
                Tax Name {sortColumnOverride === "taxName" ? (sortDirectionOverride === "ASC" ? "↑" : "↓") : ""}
              </TableCell>
              <TableCell onClick={() => handleSortOverride("taxRate")}>
                Tax Rate {sortColumnOverride === "taxRate" ? (sortDirectionOverride === "ASC" ? "↑" : "↓") : ""}
              </TableCell>
              {showDeleted && (
                <>
                  <TableCell onClick={() => handleSortOverride("effectiveFrom")}>
                    Effective From {sortColumnOverride === "effectiveFrom" ? (sortDirectionOverride === "ASC" ? "↑" : "↓") : ""}
                  </TableCell>
                  <TableCell onClick={() => handleSortOverride("effectiveUntil")}>
                    Effective Until {sortColumnOverride === "effectiveUntil" ? (sortDirectionOverride === "ASC" ? "↑" : "↓") : ""}
                  </TableCell>
                  <TableCell onClick={() => handleSortOverride("hydraState")}>
                    Hydra State {sortColumnOverride === "hydraState" ? (sortDirectionOverride === "ASC" ? "↑" : "↓") : ""}
                  </TableCell>
                </>
              )}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {overrideRates.map((tax) => (
              <TableRow key={tax.id}>
                <TableCell>{tax.geoProvince.provinceName}</TableCell>
                <TableCell>{tax.revenueTrackingCode}</TableCell>
                <TableCell>{tax.taxName}</TableCell>
                <TableCell>{tax.taxRate}</TableCell>
                {showDeleted && (
                  <>
                    <TableCell>{tax.effectiveFrom}</TableCell>
                    <TableCell>{tax.effectiveUntil}</TableCell>
                    <TableCell>{tax.hydraState}</TableCell>
                  </>
                )}
                <TableCell>
                  <Button color="error" onClick={() => handleOpenConfirmModal(tax.id, true)}>Delete</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalRecords}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </Paper>
  );
};

export default OverrideTaxRatesTable;
